import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import moment from 'moment';
import { Drawer } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useHistory } from '../../providers/HistoryProvider';
import { useProjection } from '../../providers/ProjectionProvider';

const truncateArray = (arr: any[], limit: number) => {
  const clonedArr = [...arr];
  const displayArr = clonedArr.splice(0, limit);
  if (clonedArr.length > 0) {
    displayArr.push(`+${clonedArr.length} more`);
  }
  return displayArr.join(', ');
};

export const ForecastHistoryWidget = () => {
  const historyContext = useHistory();
  const history = historyContext.getHistory();
  const [size, setSize] = React.useState<number>(300);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const projectionContext = useProjection();
  const handleOpen = (value: number) => {
    setSize(value);
    setOpen(true);
  };

  if (!history) return <div></div>;

  return (
    <>
      <div className="">
        <Button
          variant="default"
          className="btn btn-default mx-2"
          onClick={() => {
            handleOpen(400);
          }}
        >
          <FontAwesomeIcon icon={faHistory} className="ms-2" />
          <Badge bg="info" pill className="ms-2">
            {history.histories.length}
          </Badge>
        </Button>
      </div>
      <Drawer size={size} placement="right" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Version history</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ paddingRight: 20 }}>
          <div style={{ height: '100%', overflowY: 'auto', position: 'relative' }}>
            {history.histories.map((item) => {
              const brandChanges =
                item.forecastBudgetHistories
                  .filter((item: any) => item?.forecastBudget?.budgetType === 'brand')
                  .map((item: any) => item?.forecastBudget?.brand?.name) || [];
              const brandFamilyChanges =
                item.forecastBudgetHistories
                  .filter((item: any) => item?.forecastBudget?.budgetType === 'brandFamily')
                  .map((item: any) => item?.forecastBudget?.brandFamily?.name) || [];
              const productChanges =
                item.forecastProductHistories.map((item: any) => item?.forecastProduct?.product?.label?.name) || [];

              return (
                <div
                  key={item.historyId}
                  className="d-flex justify-content-between my-2 cursor-pointer"
                  style={{ paddingBottom: 8, borderBottom: '1px solid #cdcdcd' }}
                >
                  <div>
                    <div>{item.createdAt ? moment(item.createdAt).format('MMM D, hh:mm A') : ''}</div>
                    {item.notes && (
                      <div className="d-flex justify-content-between">
                        <div className="">Notes: {item.notes ?? ''}</div>
                      </div>
                    )}
                    <div>
                      {!!brandChanges.length && (
                        <div>
                          ({brandChanges.length}) Brand Changes: {truncateArray(brandChanges, 3)}
                        </div>
                      )}
                      {!!brandFamilyChanges.length && (
                        <div>
                          ({brandFamilyChanges.length}) BrandFamily Changes: {truncateArray(brandFamilyChanges, 3)}
                        </div>
                      )}
                      {!!productChanges.length && (
                        <div>
                          ({productChanges.length}) Product Changes: {truncateArray(productChanges, 3)}
                        </div>
                      )}
                    </div>
                    <Badge bg="secondary" className="me-2">
                      {[item.createdByUser?.firstName, item.createdByUser?.lastName].filter(Boolean).join(' ')}
                    </Badge>
                    <div className="d-flex justify-content-between">
                      <div className="">Id: {item.historyId ?? ''}</div>
                    </div>
                  </div>
                  <div>
                    <DropdownButton size="sm" title="" variant="default">
                      <Dropdown.Item
                        onClick={async () => {
                          setLoading(true);
                          try {
                            await historyContext.handlers.rollback(item.historyId);
                            await projectionContext.handlers.fetch(item.forecastId);
                            window.location.reload();
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        Rollback to this version
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={async () => {
                        setLoading(true);
                        try {
                          await historyContext.handlers.remove(item.historyId);
                        } finally {
                          setLoading(false);
                        }
                      }}>Remove this version</Dropdown.Item> */}
                    </DropdownButton>
                  </div>
                </div>
              );
            })}
          </div>
        </Drawer.Body>
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: '#cdcdcd', opacity: 0.5 }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default ForecastHistoryWidget;
