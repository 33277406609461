export const validateOnChangeInput = (
  e: React.ChangeEvent<HTMLInputElement>,
  inputId: string,
  updatedType: string,
  handleChange: (id: string, data: any) => void,
) => {
  const inputValue = e.target.value;
  if (!(inputValue.length === 0 || /^-?\d*\.?\d*$/.test(inputValue))) {
    e.preventDefault();
    return false;
  } else {
    handleChange(inputId, {
      [updatedType]: inputValue || '',
    });
  }
};

export const validationEmailFormat = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};
