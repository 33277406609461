import { IconDefinition, faChartLine, faGear, faGears } from '@fortawesome/free-solid-svg-icons';

interface IDashboardItemChild {
  href: string;
  title: string;
}

export interface IDashboardItem {
  href: string;
  title: string;
  badge?: number;
  icon?: IconDefinition;
  children?: IDashboardItemChild[];
}

export const forecastSection: IDashboardItem = {
  href: '/forecast',
  icon: faChartLine,
  title: 'Sales Forecasting',
  children: [
    {
      href: '/forecast/dashboard',
      title: 'Dashboard',
    },

    {
      href: '/forecast/region',
      title: 'Regional Forecast',
    },
    {
      href: '/forecast/brand',
      title: 'Brand Forecast',
    },
    {
      href: '/forecast/top100',
      title: 'Top 100 Products',
    },
  ],
};
export const analysisSection: IDashboardItem = {
  href: '/analysis',
  icon: faGears,
  title: 'Analysis',
  children: [
    {
      href: '/analysis',
      title: 'Case and Revenue',
    },
  ],
};

export const adminSection = {
  href: '/admin',
  icon: faGear,
  title: 'Administration',
  children: [
    {
      href: '/admin/forecast',
      title: 'Forecast',
    },
    {
      href: '/admin/product',
      title: 'Product',
    },
    {
      href: '/admin/user',
      title: 'User',
    },
    {
      href: '/admin/geography',
      title: 'Geography',
    },
    {
      href: '/admin/import-export',
      title: 'Import/Export',
    },
    {
      href: '/admin/brand',
      title: 'Brand',
    },
  ],
};
