import React from 'react';

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Dashboards
import PagePermissionSwitch from './components/PagePermissionSwitch';
import Dashboard from './pages/Dashboard';
import MainLayout from './layouts/MainLayout';
import Management from './pages/Management';
import AdminForecast from './pages/AdminForecast';
import LogIn from './pages/LogIn';
import { Navigate } from 'react-router-dom';
import BrandForecastProjection from './pages/BrandForecastProjection';
import ProjectionSummary from './pages/ProjectionSummary';
import ProductForecastProjection from './pages/ProductForecastProjection';
import RegionalForecast from './pages/RegionalForecast';
import TopProducts from './pages/TopProducts';
import UserList from './pages/AdminUser';
import User from './pages/AdminUser/User';
import ImportConfirm from './pages/AdminImport-Export/ImportConfirmTable';
import ImportExport from './pages/AdminImport-Export';
import Brand from './pages/Admin/Brand';
import Page404 from './pages/404Page';
import Page403 from './pages/403Page';
import Page500 from './pages/500Page';
import AnalysisPage from './pages/Analysis';
import AdminTerritories from './pages/Admin/Territories';
import ProductAndLabel from './pages/Admin/ProductAndLabel';

const routes = [
  {
    path: '/',
    element: <Navigate to="forecast" replace={true} />,
  },
  {
    path: 'login',
    element: <LogIn />,
  },
  {
    path: 'forecast',
    element: <MainLayout isAdmin={false} />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'management-growth',
        element: <Management />,
      },
      {
        path: 'brand',
        element: <PagePermissionSwitch cases={{ admin: BrandForecastProjection, manager: BrandForecastProjection }} />,
      },
      {
        path: 'product/:id',
        element: (
          <PagePermissionSwitch cases={{ admin: ProductForecastProjection, manager: ProductForecastProjection }} />
        ),
      },
      {
        path: 'projection/:id',
        element: <ProjectionSummary />,
      },
      {
        path: 'region',
        element: <RegionalForecast />,
      },
      {
        path: 'top100',
        element: <TopProducts />,
      },
    ],
  },
  {
    path: 'analysis',
    element: (
      <MainLayout isAdmin={false}>
        <AnalysisPage />
      </MainLayout>
    ),
  },
  {
    path: 'admin',
    element: <MainLayout isAdmin={true} />,
    children: [
      {
        path: 'forecast',
        element: <AdminForecast />,
      },
      {
        path: 'user',
        element: <UserList />,
      },
      {
        path: 'user/:userId',
        element: <User />,
      },
      {
        path: 'geography',
        element: <AdminTerritories />,
      },
      {
        path: 'import-export',
        element: <ImportExport />,
      },
      {
        path: 'import-confirm',
        element: <ImportConfirm />,
      },
      {
        path: 'brand',
        element: <Brand />,
      },
      {
        path: 'product',
        element: <ProductAndLabel />,
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
  {
    path: '/404',
    element: <Page404 />,
  },
  {
    path: '/403',
    element: <Page403 />,
  },
  {
    path: '/500',
    element: <Page500 />,
  },
];

export default routes;
