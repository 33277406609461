import { formatNegativeNumFromStr } from './formatNum';
import { linearRegression, linearRegressionLine } from 'simple-statistics';

export const calculateForecastQuantity = (
  prevQuantity: number,
  prevSalesGrowth: number | string,
  salesGrowth: number | string,
) => {
  // can be a negative number inside a string
  const prevSalesGrowthNumber = formatNegativeNumFromStr(prevSalesGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);
  return (Number(prevQuantity) / (100 + prevSalesGrowthNumber)) * (100 + Number(salesGrowthNumber));
};

export const calculateForecastRevenue = (
  prevRevenue: number,
  prevSalesGrowth: number,
  prevPriceGrowth: number | string,
  salesGrowth: number,
  priceGrowth: number | string,
) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);
  const prevPriceGrowthNumber = formatNegativeNumFromStr(prevPriceGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);
  const prevSalesGrowthNumber = formatNegativeNumFromStr(prevSalesGrowth);

  return (
    (Number(prevRevenue) * (100 + salesGrowthNumber) * (100 + priceGrowthNumber)) /
    (100 + prevSalesGrowthNumber) /
    (100 + prevPriceGrowthNumber)
  );
};

export const calculateRevenueGrowth = (salesGrowth: number, priceGrowth: number | string) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);

  return (salesGrowthNumber * priceGrowthNumber) / 100 + salesGrowthNumber + priceGrowthNumber;
};

export const calculateExecQuantity = (execQuantity: number, salesGrowth: number | string) => {
  // can be a negative number inside a string
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);

  return (execQuantity * salesGrowthNumber) / 100;
};

export const calculateExecRevenue = (execRevenue: number, priceGrowth: number | string) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);

  return (execRevenue * priceGrowthNumber) / 100;
};

export const checkNegativeAndDecimalBeforeCalulateRound = (value: string | number): string | number => {
  const result =
    typeof value === 'string'
      ? value === '-' || value.endsWith('.') || value.includes('0')
        ? value
        : parseFloat(value)
      : Math.floor(value * 100) / 100;
  return result;
};

export const darkenRgbaColor = (rgba: any, percent: number) => {
  const rgbaMatch = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([0-9.]+)\)/);

  if (rgbaMatch) {
    const [_, rStr, gStr, bStr, aStr] = rgbaMatch;
    const r = parseInt(rStr);
    const g = parseInt(gStr);
    const b = parseInt(bStr);
    const a = parseFloat(aStr);

    const newR = Math.max(0, Math.min(255, r * (1 - percent / 100)));
    const newG = Math.max(0, Math.min(255, g * (1 - percent / 100)));
    const newB = Math.max(0, Math.min(255, b * (1 - percent / 100)));

    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  } else {
    return rgba;
  }
};

export const calculateLinearRegression = (data: number[]) => {
  const xValues = data.map((_, index) => index + 1); // Use indices as x values
  const yValues = data;

  const dataPoints = xValues.map((x, i) => [x, yValues[i]]);

  const regression = linearRegression(dataPoints);
  const regressionLineFunc = linearRegressionLine(regression);

  const regLine = xValues.map((x) => ({
    x,
    y: regressionLineFunc(x),
  }));

  return regLine;
};
