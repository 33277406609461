import { useEffect } from 'react';
import routes from './routes';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import AuthService from './services/auth.service';
import { useAuth } from './providers/AuthProvider';
import useErrorHandling from './hooks/useErrorHandling';
import { useForecast } from './providers/ForecastProvider';
import { hideLoading, showLoading } from './lib/uiService';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const { setForecastUpdated } = useForecast();
  const { setIsLoggedIn, setRole, setUserLogo, setUserName, setisCheckingLoggedState } = useAuth();
  const logged = localStorage.getItem('loggingState');
  useEffect(() => {
    if (location.search == '?loggedIn' || logged == 'loggedIn') {
      setisCheckingLoggedState(true);
      showLoading();
      AuthService.LoginCheck()
        .then((data: any) => {
          setIsLoggedIn(true);
          setRole(data.roles[0]);
          setUserLogo(data.logoUrl);
          setUserName(data.name);
          setisCheckingLoggedState(false);
          localStorage.setItem('loggingState', 'loggedIn');
          hideLoading();
        })
        .catch((e: any) => {
          hideLoading();
          setError(e.status ? e : { status: 401 });
          setIsLoggedIn(false);
          setisCheckingLoggedState(false);
        });
    }
    const handleBackButton = () => {
      // call for forecast selection on navbar if user changes stack state
      setForecastUpdated(true);
      const status = localStorage.getItem('status') || '';
      if (status) {
        const prevPath = localStorage.getItem('prevPath') || '';
        navigate(prevPath);
      }
      localStorage.setItem('status', '');
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  useEffect(() => {
    setForecastUpdated(true);
  }, [location]);

  const content = useRoutes(routes);
  return content;
}

export default App;
