import React, { useEffect, useState } from 'react';
import BrandForecastTable from './BrandForecastTable';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import { ProjectionProvider, useProjection } from '../../providers/ProjectionProvider';
import useForecastModal from '../../hooks/useForecastModal';
import { useForecast } from '../../providers/ForecastProvider';
import ActiveProjectionWidget from '../../components/projection/ActiveProjectionWidget';
import ForecastInfoCard from '../../components/forecastInfoCard';
import ForecastService from '../../services/forecast.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import { BrandForecastProvider } from '../../providers/BrandForecastProvider';

const BrandForecastView: React.FC = () => {
  const { hasForecasts, forecastId, forecastCalendarId, currentForecastYear } = useForecast();
  const { setError } = useErrorHandling();
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const [forecastBudgetData, setForecastBudgetData] = useState({
    cases: 0,
    prevcases: 0,
    revenue: 0,
    prevrevenue: 0,
    casesGrowthForPrevYear: 0,
    revenueGrowthForPrevYear: 0,
  });
  const projectionContext = useProjection();
  const [projection, setProjection] = useState<any>(null);

  useEffect(() => {
    if (forecastId && forecastCalendarId && currentForecastYear) {
      ForecastService.GetPrevAndCurrentForecastBudget({
        forecastId,
        forecastCalendarId,
        currentForecastYear,
      })
        .then(async ({ data }) => {
          await projectionContext.handlers.fetch(forecastId);
          const projection = projectionContext.getProjection();
          setProjection(projection);
          setForecastBudgetData(data.forecastData);
        })
        .catch((error) => {
          setError({ status: error?.response?.status || 500 });
        });
    }
  }, [forecastId, forecastCalendarId, currentForecastYear]);

  const blurredStyle = {
    filter: projectionContext.isLoading ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };

  return hasForecasts ? (
    <BrandForecastProvider>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <div className="d-flex justify-content-between">
        <div className="">
          <h3 className="text-dark mb-4">Brand Summary Forecast</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ForecastInfoCard forecastBudgetData={forecastBudgetData} currentForecastYear={currentForecastYear} />
        </div>
        <div className="col" style={blurredStyle}>
          {projectionContext.isLoading && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '510px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <ActiveProjectionWidget
            header={
              <div className="d-flex justify-content-between">
                <p className="text-primary m-0 fw-bold">{projection ? 'Active Projection' : 'No Active Projection'}</p>
              </div>
            }
            projection={projection}
          />
        </div>
      </div>
      <BrandForecastTable row scope="Management" />
      <BrandForecastTable row scope="Regional" setForecastBudgetData={setForecastBudgetData} />
    </BrandForecastProvider>
  ) : (
    <h3 className="text-dark mb-4">No Forecasts In the System</h3>
  );
};

const BrandForecast = () => {
  return (
    <ProjectionProvider>
      <BrandForecastView />
    </ProjectionProvider>
  );
};

export default BrandForecast;
