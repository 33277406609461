import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTable, useSortBy, useExpanded } from 'react-table';
import { Table } from 'react-bootstrap';
import { ProjectionProvider, useProjection } from '../../providers/ProjectionProvider';

import { hideLoading, showLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { ConfigService } from '../../services/config.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faPlusCircle, faMinusCircle, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { validateOnChangeInput } from '../../utils/validation';
import { checkNegativeAndDecimalBeforeCalulateRound } from '../../utils/calculation';
import ForecastInfoCard from '../../components/forecastInfoCard';
import StageButton from '../../components/projection/StageButton';
import { ReactComponent as OctogonalXLogo } from '../../assets/img/x-octagon-fill.svg';

import DownloadSelector from '../../components/DownloadSelector';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import useForecastModal from '../../hooks/useForecastModal';
import useErrorHandling from '../../hooks/useErrorHandling';
import ActiveProjectionWidget from '../../components/projection/ActiveProjectionWidget';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IProductForecast, IProduct, ILabel, IBrandFamily, IBrandTotals } from '../../lib/projection.types';
import Pagination from '../../components/Pagination';

const config = ConfigService.GetConfig();
const mediumGrowthMin = config.growthMin;
const mediumGrowthMax = config.growthMax;
const lowBgColor = config.lowGrowthColor;
const mediumBgColor = config.mediumGrowthColor;
const highBgColor = config.highGrowthColor;

const ProductForecastView: React.FC = () => {
  const { forecastId, territoryId, hasForecasts, forecastCalendarId, currentForecastYear, isForecastFromParams } =
    useForecast();
  const { setError } = useErrorHandling();
  const location = useLocation();
  const { id: brandId } = useParams();
  const brandName = location?.state?.brandName as string;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [startPage, setStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search] = useState<string>('');
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [productForecasts, setProductForecasts] = useState<IBrandFamily[]>([]);
  const [brandTotals, setBrandTotals] = useState<IBrandTotals>({
    year2Total: 0,
    totalYear1T12: 0,
    totalYear1YTD: 0,
    totalExcQuantity: 0,
    totalExcRevenue: 0,
    totalPriceGrowth: 0,
    totalQuantity: 0,
    totalRevenue: 0,
  });
  const [forecastBudgetData, setForecastBudgetData] = useState({
    cases: 0,
    prevcases: 0,
    revenue: 0,
    prevrevenue: 0,
    casesGrowthForPrevYear: 0,
    revenueGrowthForPrevYear: 0,
  });
  const [count, setCount] = useState<number>(0);
  const [initialData, setInitialData] = useState<IBrandFamily[]>([]);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isDescSorted, setIsDescSorted] = useState<boolean>(true);
  const curYear = new Date().getFullYear();
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const [projection, setProjection] = useState<any>(null);

  const projectionContext = useProjection();

  const tableItemStyle = {
    backgroundColor: 'unset',
    '--bs-table-accent-bg': 'unset',
    color: 'white',
  } as React.CSSProperties;

  useEffect(() => {
    fetchForecastProduct();
  }, [page, pageSize, forecastId, search, territoryId, forecastCalendarId]);

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  useEffect(() => {
    if (forecastId && forecastCalendarId && currentForecastYear) {
      ForecastService.GetPrevAndCurrentForecastBudget({
        forecastId,
        forecastCalendarId,
        currentForecastYear,
      })
        .then(({ data }) => {
          setForecastBudgetData(data.forecastData);
        })
        .catch((response) => {
          setError({ status: response.status || 500 });
        });
    }
  }, [forecastId, forecastCalendarId]);

  const calculateDiffs = (data: IBrandFamily[]) => {
    const dataToInsert = data.map((brandFamily) => {
      let totalBrandYear2 = 0;
      let totalBrandYear1T12 = 0;
      let totalBrandYear1YTD = 0;
      const revenue = brandFamily.forecastBudget?.revenue ?? 0;
      let totalBrandExecSalesGrowth = 0;
      let totalBrandSalesGrowth = 0;
      let totalBrandPriceGrowth = 0;
      let totalBrandExecPriceGrowth = 0;
      let totalBrandQuantity = 0;
      let totalBrandSalesDiffPercent = 0;
      let totalBrandRevenueDiffPercent = 0;

      const labelToInsert = brandFamily.labels.map((label) => {
        let totalLabelYear2 = 0;
        let totalLabelYear1T12 = 0;
        let totalLabelYear1YTD = 0;
        let totalLabelExecRevenue = 0;
        let totalLabelRevenue = 0;
        let totalLabelExecSalesGrowth = 0;
        let totalLabelSalesGrowth = 0;
        let totalLabelPriceGrowth = 0;
        let totalLabelExecPriceGrowth = 0;
        let totalLabelExecQuantity = 0;
        let totalLabelQuantity = 0;
        let totalLabelSalesDiffPercent = 0;
        let totalLabelRevenueDiffPercent = 0;
        const productsWithDiffs = label.products.map((prod: IProduct) => {
          const {
            year2,
            year1T12,
            year1YTD,
            execQuantity,
            revenue,
            execSalesGrowth,
            execRevenue,
            forecastProducts,
            price,
          } = prod.forecastProducts.reduce(
            (acc, forecast: IProductForecast) => {
              acc.year2 += Number(forecast.year2.totalQuantity);
              acc.year1T12 += Number(forecast.year1T12.totalQuantity);
              acc.year1YTD += Number(forecast.year1YTD.totalQuantity);
              acc.execQuantity += Number(forecast.execQuantity);
              acc.revenue += Number(forecast.revenue);
              acc.execSalesGrowth = Number(forecast.execSalesGrowth);
              acc.execRevenue += Number(forecast.execRevenue);
              acc.price += Number(forecast.price);
              return acc;
            },
            {
              year2: 0,
              year1T12: 0,
              year1YTD: 0,
              execQuantity: 0,
              revenue: 0,
              execSalesGrowth: 0,
              execRevenue: 0,
              forecastProducts: prod.forecastProducts,
              price: 0,
            },
          );

          const forecast = forecastProducts.map((forecast: IProductForecast) => {
            const salesDiffPercent =
              Number(forecast.execQuantity) !== 0
                ? (Number(forecast.quantity) / Number(forecast.execQuantity)) * 100 - 100
                : 0;
            const revenueDiffPercent =
              Number(forecast.execRevenue) !== 0
                ? (Number(forecast.revenue) / Number(forecast.execRevenue)) * 100 - 100
                : 0;
            return {
              ...forecast,
              salesDiffPercent,
              revenueDiffPercent,
            };
          });
          return {
            ...prod,
            forecastProducts: forecast,
            year2,
            year1T12,
            year1YTD,
            execQuantity,
            execSalesGrowth,
            execRevenue,
            revenue,
            price,
          };
        });

        label.products.forEach((product) => {
          product.forecastProducts.forEach((forecastProduct) => {
            totalLabelYear2 += Number(forecastProduct.year2.totalQuantity);
            totalLabelYear1T12 += Number(forecastProduct.year1T12.totalQuantity);
            totalLabelYear1YTD += Number(forecastProduct.year1YTD.totalQuantity);
            totalLabelExecRevenue += Number(forecastProduct.execRevenue);
            totalLabelRevenue += Number(forecastProduct.revenue);
            totalLabelExecSalesGrowth = Number(forecastProduct.execSalesGrowth);
            totalLabelSalesGrowth = Number(forecastProduct.salesGrowth);
            totalLabelPriceGrowth = Number(forecastProduct.priceGrowth);
            totalLabelExecPriceGrowth = Number(forecastProduct.execPriceGrowth);
            totalLabelExecQuantity += Number(forecastProduct.execQuantity);
            totalLabelQuantity += Number(forecastProduct.quantity);
            totalLabelSalesDiffPercent += Number(forecastProduct.salesDiffPercent);
            totalLabelRevenueDiffPercent += Number(forecastProduct.revenueDiffPercent);
          });
        });

        return {
          ...label,
          products: productsWithDiffs,
          totalLabelYear2,
          totalLabelYear1T12,
          totalLabelYear1YTD,
          totalLabelExecRevenue,
          totalLabelExecSalesGrowth,
          totalLabelSalesGrowth,
          totalLabelQuantity,
          totalLabelPriceGrowth,
          totalLabelRevenue,
          totalLabelExecQuantity,
          totalLabelExecPriceGrowth,
          totalLabelSalesDiffPercent,
          totalLabelRevenueDiffPercent,
        };
      });
      labelToInsert.forEach((label) => {
        totalBrandYear2 += Number(label.totalLabelYear2);
        totalBrandYear1T12 += Number(label.totalLabelYear1T12);
        totalBrandYear1YTD += Number(label.totalLabelYear1YTD);
        totalBrandExecSalesGrowth = Number(label.totalLabelExecSalesGrowth);
        totalBrandSalesGrowth = Number(label.totalLabelSalesGrowth);
        totalBrandPriceGrowth = Number(label.totalLabelPriceGrowth);
        totalBrandExecPriceGrowth = Number(label.totalLabelExecPriceGrowth);
        totalBrandQuantity += Number(label.totalLabelQuantity);
        totalBrandSalesDiffPercent += Number(label.totalLabelSalesDiffPercent);
        totalBrandRevenueDiffPercent += Number(label.totalLabelRevenueDiffPercent);
      });
      return {
        ...brandFamily,
        ...brandFamily.forecastBudget,
        name: brandFamily.name,
        brandFamilyId: brandFamily.brandFamilyId,
        totalBrandYear2,
        totalBrandYear1T12,
        totalBrandYear1YTD,
        totalBrandExecSalesGrowth,
        totalBrandExecPriceGrowth,
        totalBrandPriceGrowth,
        totalBrandQuantity,
        totalBrandSalesGrowth,
        totalBrandSalesDiffPercent,
        revenue,
        totalBrandRevenueDiffPercent,
        labels: labelToInsert,
      };
    });

    setProductForecasts(dataToInsert);
    setInitialData(dataToInsert);
    calculateTotals(dataToInsert);
  };

  const calculateTotals = (data: any) => {
    const totals = data.reduce(
      (acc: any, forecast: any) => {
        acc.year2Total += Number(forecast.totalBrandYear2);
        acc.totalYear1T12 += Number(forecast.totalBrandYear1T12);
        acc.totalYear1YTD += Number(forecast.totalBrandYear1YTD);
        acc.totalExcQuantity += Number(forecast.execQuantity);
        acc.totalExcRevenue += Number(forecast.execRevenue);
        acc.totalPriceGrowth += Number(forecast.priceGrowth);
        acc.totalQuantity += Number(forecast.quantity);
        acc.totalRevenue += Number(forecast.revenue);
        return acc;
      },
      {
        year2Total: 0,
        totalYear1T12: 0,
        totalYear1YTD: 0,
        totalExcQuantity: 0,
        totalExcRevenue: 0,
        totalPriceGrowth: 0,
        totalQuantity: 0,
        totalRevenue: 0,
      },
    );

    setBrandTotals(totals);
  };

  const fetchForecastProduct = async () => {
    if (hasForecasts && forecastId && territoryId && forecastCalendarId) {
      showLoading();
      try {
        const { data } = await ForecastService.GetForecastProduct({
          page,
          pageSize,
          forecastId,
          search,
          territoryId,
          brandId,
          forecastCalendarId,
        });
        await projectionContext.handlers.fetch(forecastId);
        const projection = projectionContext.getProjection();
        setProjection(projection);
        if (projection) {
          projection.applyToForecastData(data.data || []);
        }
        calculateDiffs(data.data || []);
        setCount(data.count);
        setIsEditable(data.isEditable);
        setTotalPages(Math.ceil(data.count / pageSize));

        hideLoading();
      } catch (error: any) {
        console.log(error);
        setError({ status: error.response.status || 500 });
        hideLoading();
      }
    }
  };

  const handleSaveForecastProduct = (forecast: IProductForecast) => {
    showLoading();
    projectionContext.handlers
      .update({
        territoryId,
        forecastId,
        isExec: false,
        data: [
          {
            forecastProductId: forecast.forecastProductId,
            salesGrowth: Number(forecast.salesGrowth),
            priceGrowth: Number(forecast.priceGrowth),
            quantity: Number(forecast.quantity),
            explanation: forecast.explanation,
          },
        ],
      })
      .then(() => {
        projectionContext.handlers.fetch(forecastId);
        setProductForecasts((prevData: any) => {
          const response = prevData.map((brand: IBrandFamily) => ({
            ...brand,
            labels: brand.labels.map((label: ILabel) => ({
              ...label,
              products: label.products.map((prod: IProduct) => ({
                ...prod,
                forecastProducts: prod.forecastProducts.map((forecastItem: IProductForecast) =>
                  forecastItem.forecastProductId === forecast.forecastProductId
                    ? {
                        ...forecastItem,
                        isOverridden: true,
                      }
                    : forecastItem,
                ),
              })),
            })),
          }));
          setInitialData(response);
          return response;
        });
        toast.success('Change Successfully Staged');
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  };

  const handleSaveForecastBrand = (forecast: any) => {
    showLoading();
    const data = forecast.map(
      ({
        forecastBudgetId,
        salesGrowth,
        priceGrowth,
        quantity,
        explanation,
      }: {
        forecastBudgetId: any;
        salesGrowth: any;
        priceGrowth: any;
        quantity: any;
        explanation: any;
      }) => ({
        forecastBudgetId,
        salesGrowth,
        priceGrowth,
        quantity,
        explanation,
      }),
    );
    projectionContext.handlers
      .update({
        territoryId,
        forecastId,
        isExec: false,
        data,
      })
      .then((_res: any) => {
        toast.success('Change Successfully Staged');
        hideLoading();
      })
      .catch((error: any) => {
        hideLoading();
        console.log(error);
      });
  };

  const handleChangeForecastProduct = (id: string, updatedData: any) => {
    setProductForecasts((brandFamily: any) => {
      const isProductInsiedaBrand = brandFamily.some((brandF: any) =>
        brandF.labels.some((label: ILabel) =>
          label.products.some((product: IProduct) =>
            product.forecastProducts.some(
              (forecastProduct: IProductForecast) => forecastProduct.forecastProductId === id,
            ),
          ),
        ),
      );

      if (isProductInsiedaBrand) {
        const response = brandFamily.map((brandFamily: any) => {
          // let prevSalesGrowth = 0;
          // let prevPriceGrowth = 0;
          let prevQuantity = 0;
          const labels = brandFamily.labels.map((label: any) => {
            return {
              ...label,
              totalLabelRevenue:
                updatedData.salesGrowth !== undefined
                  ? label.products.reduce((accProduct: any, currentProduct: any) => {
                      currentProduct.quantity =
                        Number(currentProduct.execQuantity) *
                        ((formatNegativeNumFromStr(updatedData.salesGrowth) + 100) /
                          (Number(currentProduct.execSalesGrowth) + 100));
                      const revenue = Number(currentProduct.price) * currentProduct.quantity;
                      return accProduct + revenue;
                    }, 0)
                  : updatedData.priceGrowth !== undefined
                    ? label.products.reduce((accProduct: any, currentProduct: any) => {
                        const forecastData = currentProduct.forecastProducts[0];
                        currentProduct.revenue =
                          Number(forecastData.execRevenue) *
                          ((formatNegativeNumFromStr(updatedData.priceGrowth) + 100) /
                            (Number(forecastData.execPriceGrowth) + 100));
                        return accProduct + currentProduct.revenue;
                      }, 0)
                    : label.totalLabelRevenue,
              products: label.products.map((prod: IProduct) => {
                const forecastData = prod.forecastProducts[0];
                if (forecastData.forecastProductId === id) {
                  // prevSalesGrowth = forecastData.salesGrowth;
                  // prevPriceGrowth = forecastData.priceGrowth;
                  prevQuantity = forecastData.quantity;
                  const prevRevenue = forecastData.revenue;
                  if (updatedData.salesGrowth !== undefined) {
                    forecastData.quantity =
                      Number(forecastData.execQuantity) *
                      ((formatNegativeNumFromStr(updatedData.salesGrowth) + 100) /
                        (Number(forecastData.execSalesGrowth) + 100));
                    forecastData.salesGrowth = updatedData.salesGrowth;
                    forecastData.revenue = Number(prod.price) * forecastData.quantity;
                    setForecastBudgetData((prevData: any) => {
                      return {
                        ...prevData,
                        cases: Number(prevData.cases) - prevQuantity + forecastData.quantity,
                        revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                      };
                    });
                  }
                  if (updatedData.quantity !== undefined) {
                    forecastData.salesGrowth =
                      Number(forecastData.execQuantity) !== 0
                        ? (formatNegativeNumFromStr(updatedData.quantity) / Number(forecastData.execQuantity)) * 100 -
                          100
                        : 0.0;
                    forecastData.quantity = updatedData.quantity;
                    forecastData.revenue = Number(prod.price) * formatNegativeNumFromStr(updatedData.quantity);
                    setForecastBudgetData((prevData: any) => {
                      return {
                        ...prevData,
                        cases: Number(prevData.cases) - prevQuantity + forecastData.quantity,
                        revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                      };
                    });
                  }
                  if (updatedData.priceGrowth !== undefined) {
                    forecastData.revenue =
                      Number(forecastData.execRevenue) *
                      ((formatNegativeNumFromStr(updatedData.priceGrowth) + 100) /
                        (Number(forecastData.execPriceGrowth) + 100));
                    forecastData.priceGrowth = updatedData.priceGrowth;
                    setForecastBudgetData((prevData: any) => {
                      return {
                        ...prevData,
                        revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                      };
                    });
                  }

                  forecastData.salesDiffPercent =
                    Number(forecastData.execQuantity) !== 0
                      ? (Number(forecastData.quantity) / Number(forecastData.execQuantity)) * 100 - 100
                      : 0;
                  forecastData.revenueDiffPercent =
                    Number(forecastData.execRevenue) !== 0
                      ? (Number(forecastData.revenue) / Number(forecastData.execRevenue)) * 100 - 100
                      : 0;

                  return {
                    ...prod,
                    forecastProducts: [{ ...forecastData, ...updatedData }],
                  };
                } else {
                  return {
                    ...prod,
                    forecastProducts: [forecastData],
                  };
                }
              }),
            };
          });
          // update brandFamily based on product data
          if (updatedData.salesGrowth !== undefined) {
            brandFamily.quantity = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  currentProduct.quantity =
                    Number(currentProduct.execQuantity) *
                    ((formatNegativeNumFromStr(updatedData.salesGrowth) + 100) /
                      (Number(currentProduct.execSalesGrowth) + 100));
                  return accProduct + currentProduct.quantity;
                }, 0)
              );
            }, 0);
            brandFamily.revenue = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  currentProduct.quantity =
                    Number(currentProduct.execQuantity) *
                    ((formatNegativeNumFromStr(updatedData.salesGrowth) + 100) /
                      (Number(currentProduct.execSalesGrowth) + 100));
                  const revenue = Number(currentProduct.price) * currentProduct.quantity;
                  return accProduct + revenue;
                }, 0)
              );
            }, 0);
          }
          if (updatedData.quantity !== undefined) {
            brandFamily.quantity =
              Number(brandFamily.quantity) - prevQuantity + formatNegativeNumFromStr(updatedData.quantity);
            brandFamily.revenue =
              brandFamily.revenue +
              brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
                return (
                  accLabel +
                  currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                    const revenue = Number(currentProduct.price) * formatNegativeNumFromStr(updatedData.quantity);
                    return accProduct + revenue;
                  }, 0)
                );
              }, 0);
          }
          if (updatedData.priceGrowth !== undefined) {
            brandFamily.revenue = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  return (
                    accProduct +
                    currentProduct.forecastProducts.reduce((accForecast: any, currentForecast: any) => {
                      const revenue =
                        Number(currentForecast.execRevenue) *
                        ((Number(brandFamily.priceGrowth) + 100) / (Number(currentForecast.execPriceGrowth) + 100));
                      return accForecast + revenue;
                    }, 0)
                  );
                }, 0)
              );
            }, 0);
          }

          brandFamily.totalBrandSalesDiffPercent =
            Number(brandFamily.execQuantity) !== 0
              ? (Number(brandFamily.quantity) / Number(brandFamily.execQuantity)) * 100 - 100
              : 0;
          brandFamily.totalBrandRevenueDiffPercent =
            Number(brandFamily.execRevenue) !== 0
              ? (Number(brandFamily.revenue) / Number(brandFamily.execRevenue)) * 100 - 100
              : 0;
          return {
            ...brandFamily,
            labels,
          };
        });
        const totals = response.reduce(
          (acc: any, forecast: any) => {
            acc.totalPriceGrowth += formatNegativeNumFromStr(forecast.priceGrowth);
            acc.totalQuantity += formatNegativeNumFromStr(forecast.quantity);
            acc.totalRevenue += formatNegativeNumFromStr(forecast.revenue);
            return acc;
          },
          {
            totalPriceGrowth: 0,
            totalQuantity: 0,
            totalRevenue: 0,
          },
        );

        setBrandTotals((prevData) => {
          return {
            ...prevData,
            ...totals,
          };
        });
        setInitialData(response);
        return response;
      }
    });
  };

  const handleChangeForecastBrand = (id: string, updatedData: any) => {
    setProductForecasts((prevData: IBrandFamily[]) => {
      const response = prevData.map((brandFamily: any) => {
        if (brandFamily.brandFamilyId === id) {
          if (updatedData.totalBrandSalesGrowth !== undefined) {
            brandFamily.quantity =
              Number(brandFamily.execQuantity) *
              ((formatNegativeNumFromStr(updatedData.totalBrandSalesGrowth) + 100) /
                (Number(brandFamily.salesGrowth) + 100));
            brandFamily.salesGrowth = updatedData.totalBrandSalesGrowth;
            brandFamily.revenue = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  const revenue = Number(currentProduct.price) * brandFamily.quantity;
                  return accProduct + revenue;
                }, 0)
              );
            }, 0);
          }
          if (updatedData.totalBrandQuantity !== undefined) {
            brandFamily.salesGrowth =
              Number(brandFamily.execQuantity) !== 0
                ? (formatNegativeNumFromStr(updatedData.totalBrandQuantity) / Number(brandFamily.execQuantity)) * 100 -
                  100
                : 0.0;
            brandFamily.quantity = updatedData.totalBrandQuantity;
            brandFamily.revenue = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  const revenue =
                    Number(currentProduct.price) * formatNegativeNumFromStr(updatedData.totalBrandQuantity);
                  return accProduct + revenue;
                }, 0)
              );
            }, 0);
          }
          if (updatedData.totalBrandPriceGrowth !== undefined) {
            brandFamily.priceGrowth = updatedData.totalBrandPriceGrowth;
            brandFamily.revenue = brandFamily.labels.reduce((accLabel: any, currentLabel: any) => {
              return (
                accLabel +
                currentLabel.products.reduce((accProduct: any, currentProduct: any) => {
                  return (
                    accProduct +
                    currentProduct.forecastProducts.reduce((accForecast: any, currentForecast: any) => {
                      const revenue =
                        Number(currentForecast.execRevenue) *
                        ((formatNegativeNumFromStr(updatedData.totalBrandPriceGrowth) + 100) /
                          (Number(currentForecast.execPriceGrowth) + 100));
                      return accForecast + revenue;
                    }, 0)
                  );
                }, 0)
              );
            }, 0);
          }

          brandFamily.totalBrandSalesDiffPercent =
            Number(brandFamily.execQuantity) !== 0
              ? (Number(brandFamily.quantity) / Number(brandFamily.execQuantity)) * 100 - 100
              : 0;
          brandFamily.totalBrandRevenueDiffPercent =
            Number(brandFamily.execRevenue) !== 0
              ? (Number(brandFamily.revenue) / Number(brandFamily.execRevenue)) * 100 - 100
              : 0;
          return {
            ...brandFamily,
            ...updatedData,
            labels: brandFamily.labels.map((label: any) => {
              return {
                ...label,
                totalLabelRevenue:
                  updatedData.totalBrandSalesGrowth !== undefined
                    ? label.products.reduce((accProduct: any, currentProduct: any) => {
                        currentProduct.quantity =
                          Number(currentProduct.execQuantity) *
                          ((formatNegativeNumFromStr(updatedData.totalBrandSalesGrowth) + 100) /
                            (Number(currentProduct.execSalesGrowth) + 100));
                        const revenue = Number(currentProduct.price) * currentProduct.quantity;
                        return accProduct + revenue;
                      }, 0)
                    : updatedData.totalBrandPriceGrowth !== undefined
                      ? label.products.reduce((accProduct: any, currentProduct: any) => {
                          const forecastData = currentProduct.forecastProducts[0];
                          currentProduct.revenue =
                            Number(forecastData.execRevenue) *
                            ((formatNegativeNumFromStr(updatedData.totalBrandPriceGrowth) + 100) /
                              (Number(forecastData.execPriceGrowth) + 100));
                          return accProduct + currentProduct.revenue;
                        }, 0)
                      : label.totalLabelRevenue,
                products: label.products.map((prod: IProduct) => {
                  const forecastData = prod.forecastProducts[0];
                  if (!forecastData.isOverridden) {
                    const prevQuantity = forecastData.quantity;
                    const prevRevenue = forecastData.revenue;
                    if (updatedData.totalBrandSalesGrowth !== undefined) {
                      forecastData.quantity =
                        Number(forecastData.execQuantity) *
                        ((formatNegativeNumFromStr(updatedData.totalBrandSalesGrowth) + 100) /
                          (Number(forecastData.execSalesGrowth) + 100));
                      forecastData.salesGrowth = updatedData.totalBrandSalesGrowth;
                      forecastData.revenue = Number(prod.price) * forecastData.quantity;
                      setForecastBudgetData((prevData: any) => {
                        return {
                          ...prevData,
                          cases: Number(prevData.cases) - prevQuantity + forecastData.quantity,
                          revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                        };
                      });
                    }
                    if (updatedData.totalBrandQuantity !== undefined) {
                      forecastData.salesGrowth =
                        Number(forecastData.execQuantity) !== 0
                          ? (formatNegativeNumFromStr(updatedData.totalBrandQuantity) /
                              Number(forecastData.execQuantity)) *
                              100 -
                            100
                          : 0.0;
                      forecastData.revenue =
                        Number(prod.price) * formatNegativeNumFromStr(updatedData.totalBrandQuantity);
                      forecastData.quantity = updatedData.totalBrandQuantity;
                      setForecastBudgetData((prevData: any) => {
                        return {
                          ...prevData,
                          cases: Number(prevData.cases) - prevQuantity + forecastData.quantity,
                          revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                        };
                      });
                    }
                    if (updatedData.totalBrandPriceGrowth !== undefined) {
                      forecastData.revenue =
                        Number(forecastData.execRevenue) *
                        ((formatNegativeNumFromStr(updatedData.totalBrandPriceGrowth) + 100) /
                          (Number(forecastData.execPriceGrowth) + 100));
                      forecastData.priceGrowth = updatedData.totalBrandPriceGrowth;
                      setForecastBudgetData((prevData: any) => {
                        return {
                          ...prevData,
                          revenue: Number(prevData.revenue) - prevRevenue + forecastData.revenue,
                        };
                      });
                    }

                    forecastData.salesDiffPercent =
                      Number(forecastData.execQuantity) !== 0
                        ? (Number(forecastData.quantity) / Number(forecastData.execQuantity)) * 100 - 100
                        : 0;
                    forecastData.revenueDiffPercent =
                      Number(forecastData.execRevenue) !== 0
                        ? (Number(forecastData.revenue) / Number(forecastData.execRevenue)) * 100 - 100
                        : 0;

                    return {
                      ...prod,
                      forecastProducts: [{ ...forecastData, ...updatedData }],
                    };
                  } else {
                    return prod;
                  }
                }),
              };
            }),
          };
        }
        return brandFamily;
      });
      const totals = response.reduce(
        (acc: any, forecast: any) => {
          acc.totalPriceGrowth += formatNegativeNumFromStr(forecast.priceGrowth);
          acc.totalQuantity += formatNegativeNumFromStr(forecast.quantity);
          acc.totalRevenue += formatNegativeNumFromStr(forecast.revenue);
          return acc;
        },
        {
          totalPriceGrowth: 0,
          totalQuantity: 0,
          totalRevenue: 0,
        },
      );

      setBrandTotals((prevData) => {
        return {
          ...prevData,
          ...totals,
        };
      });
      setInitialData(response);
      return response;
    });
  };

  // values set to any (to not cause errors) because libary's types are not compatible
  const columns: any = useMemo(() => {
    return [
      {
        id: 'product',
        accessor: 'name',
        Header: ({
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          getToggleAllRowsExpandedProps: any;
          isAllRowsExpanded: any;
        }) => (
          <div className="d-flex align-items-center w-auto">
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? (
                <FontAwesomeIcon icon={faMinusCircle} className="ms-2" />
              ) : (
                <FontAwesomeIcon icon={faPlusCircle} className="ms-2" />
              )}
            </span>
            <p className="ms-2 m-0">Product</p>
          </div>
        ),
        Cell: ({ row, value }: { row: any; value: any }) => {
          return (
            <div className="d-flex align-items-center">
              <span
                {...row.getToggleRowExpandedProps({
                  class: `ps-${row.depth * 2}`,
                })}
              >
                {row.isExpanded ? (
                  <FontAwesomeIcon icon={faMinusCircle} className="ms-2" />
                ) : (
                  <FontAwesomeIcon icon={faPlusCircle} className="ms-2" />
                )}
              </span>
              <p className="ms-2 m-0">{value}</p>
            </div>
          );
        },
        width: 2,
      },
      {
        id: 'price',
        Header: 'Price',
        width: 1,
      },
      {
        id: 'year2',
        Header: curYear - 1,
        accessor: 'totalBrandYear2',
        Cell: ({ value }: { value: any }) => {
          return `${formatNum(value, 1, 1)}`;
        },
        width: 1,
      },
      {
        id: 'year1T12',
        Header: `${curYear} T12`,
        accesor: 'totalBrandYear1T12',
        width: 1,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return `${formatNum(forecastData.totalBrandYear1T12, 1, 1)}`;
        },
      },
      {
        id: 'year1YTD',
        Header: `${curYear} YTD`,
        accesor: 'totalBrandYear1YTD',
        width: 1,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return `${formatNum(forecastData.totalBrandYear1YTD, 1, 1)}`;
        },
      },
      {
        Header: 'Management Goal',
        accessor: 'Growth',
        id: 'Growth',
        width: 1,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <React.Fragment>
              Cases: {formatNum(forecastData?.execQuantity, 1, 1)}
              &nbsp;@ {Number(forecastData.totalBrandExecSalesGrowth || '0')}%
              <br />
              Revenue: ${formatNum(forecastData?.execRevenue)}
            </React.Fragment>
          );
        },
      },
      {
        Header: `RM Forecast`,
        accessor: 'Growth2',
        id: 'Growth2',
        width: 3,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          const salesGrowthValue = checkNegativeAndDecimalBeforeCalulateRound(forecastData?.salesGrowth);
          const salesQuantityValue = checkNegativeAndDecimalBeforeCalulateRound(forecastData?.quantity);
          const salesPriceGrowthValue = checkNegativeAndDecimalBeforeCalulateRound(forecastData?.priceGrowth);
          return (
            <td style={tableItemStyle}>
              <div className="form-group">
                <label className="text-[12px] text-secondary">Sales Growth</label>
                <div className="d-flex flex-row h-75">
                  {isEditable && !isForecastFromParams ? (
                    <input
                      className="form-control text-[12px]"
                      type="text"
                      value={salesGrowthValue || ''}
                      onChange={(e) => {
                        validateOnChangeInput(
                          e,
                          forecastData.brandFamilyId,
                          'totalBrandSalesGrowth',
                          handleChangeForecastBrand,
                        );
                      }}
                    />
                  ) : (
                    <div className="text-[12px] d-flex align-items-center text-secondary">
                      {formatNum(forecastData?.salesGrowth)}
                    </div>
                  )}
                  <span className="input-group-text text-[12px]">%</span>
                </div>
              </div>
              <div className="form-group">
                <label className="text-[12px] text-secondary">Cases</label>
                <div className="d-flex flex-row">
                  {isEditable && !isForecastFromParams ? (
                    <input
                      className="form-control text-[12px]"
                      type="text"
                      value={salesQuantityValue || ''}
                      onChange={(e) => {
                        validateOnChangeInput(
                          e,
                          forecastData.brandFamilyId,
                          'totalBrandQuantity',
                          handleChangeForecastBrand,
                        );
                      }}
                    />
                  ) : (
                    <div className="text-[12px] d-flex align-items-center text-secondary">
                      {formatNum(forecastData?.quantity)}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="text-[12px] text-secondary">Price Growth</label>
                <div className="d-flex flex-row">
                  {isEditable && !isForecastFromParams ? (
                    <input
                      className="form-control text-[12px] w-[50px]"
                      type="text"
                      value={salesPriceGrowthValue || ''}
                      onChange={(e) => {
                        validateOnChangeInput(
                          e,
                          forecastData.brandFamilyId,
                          'totalBrandPriceGrowth',
                          handleChangeForecastBrand,
                        );
                      }}
                    />
                  ) : (
                    <div className="text-[12px] d-flex align-items-center text-secondary">
                      {formatNum(forecastData?.priceGrowth)}
                    </div>
                  )}
                  <span className="input-group-text text-[12px]">%</span>
                </div>
              </div>
            </td>
          );
        },
      },
      {
        id: 'revenue',
        Header: `${Number(currentForecastYear)} Revenue`,
        accessor: 'totalBrandRevenue',
        width: 2,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];

          return <td>${formatNum(forecastData.revenue)}</td>;
        },
      },
      {
        id: 'save',
        Header: '',
        width: 1,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];

          return (
            <td style={tableItemStyle}>
              {isEditable && !isForecastFromParams && (
                <StageButton
                  changedItem={forecastData}
                  handleSave={() => {
                    handleSaveForecastBrand([forecastData]);
                  }}
                />
              )}
            </td>
          );
        },
      },
    ];
  }, [isEditable, currentForecastYear, isForecastFromParams]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleSortBy, toggleAllRowsExpanded } =
    useTable(
      {
        columns: columns,
        data: useMemo(() => productForecasts, [productForecasts]),
        autoResetExpanded: false,
      },
      useSortBy,
      useExpanded,
    );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [columns]);

  const handleSearchChange = (e: any) => {
    const filteredBrandFamily = initialData.map((brand) => ({
      ...brand,
      labels: brand.labels.filter((label) => {
        const labelName = label.name.toLowerCase();
        const searchSubstring = e.target.value.toLowerCase();
        if (!searchSubstring) {
          return true;
        }
        return labelName.includes(searchSubstring);
      }),
    }));
    setProductForecasts(filteredBrandFamily);
  };

  const expandLabelSubRow = (id: string) => {
    const updatedBrands = productForecasts.map((brand) => {
      const updatedLabels = brand.labels.map((label) => {
        if (label.labelId === id) {
          return {
            ...label,
            expandLabelRow: !label.expandLabelRow,
          };
        }
        return label;
      });
      return {
        ...brand,
        labels: updatedLabels,
      };
    });
    setProductForecasts(updatedBrands);
  };

  const customSort = () => {
    const sortedBrandFamily = productForecasts.map((brand) => {
      return {
        ...brand,
        labels: brand.labels.sort((a, b) => {
          const labelNameA = a.name;
          const labelNameB = b.name;
          return isDescSorted ? labelNameB.localeCompare(labelNameA) : labelNameA.localeCompare(labelNameB);
        }),
      };
    });
    setProductForecasts(sortedBrandFamily);
    toggleSortBy('product', !isDescSorted);
    setIsDescSorted(!isDescSorted);
  };

  const downloadColumnHeaders = [
    {
      v: 'Brand Family',
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    { v: 'Price', t: 's', s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } } },
    {
      v: `${curYear - 1}`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${curYear} T12`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${curYear} YTD`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: 'Growth Cases',
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: 'Growth Revenue',
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${Number(currentForecastYear)} Sales Growth`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${Number(currentForecastYear)} Cases Growth`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${Number(currentForecastYear)} Price Growth`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
    {
      v: `${Number(currentForecastYear)} Revenue`,
      t: 's',
      s: { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } },
    },
  ];

  const columnData: any = [];

  return hasForecasts ? (
    <>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <h3 className="text-dark mb-4">Sales Forecast Product Detail: {brandName}</h3>
      <div className="row">
        <div className="col">
          <ForecastInfoCard forecastBudgetData={forecastBudgetData} currentForecastYear={currentForecastYear} />
        </div>
        <div className="col">
          <ActiveProjectionWidget
            header={
              <div className="d-flex justify-content-between">
                <p className="text-primary m-0 fw-bold">{projection ? 'Active Projection' : 'No Active Projection'}</p>
              </div>
            }
            projection={projection}
          />
        </div>
      </div>
      <div className="card shadow mb-[44px]">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Product Breakdown</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div id="dataTable_length-1" className="dataTables_length" aria-controls="dataTable">
                <label className="form-label">
                  Show&nbsp;
                  <select
                    className="d-inline-block form-select form-select-sm"
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {/* <option value="{Number.MAX_SAFE_INTEGER}">All</option> */}
                    <option value="10" selected>
                      10
                    </option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
                <label className="form-label d-flex justify-content-md-end">
                  <DownloadSelector
                    row={columnData}
                    fileName={`${brandName} Forecast`}
                    downloadColumnHeaders={downloadColumnHeaders}
                    prepareDataFunction={() => {
                      productForecasts.forEach((item: any) => {
                        columnData.push([
                          {
                            v: `${item.name as string}`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: '',
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: formatNumOneDecimal(item.totalBrandYear2),
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: formatNumOneDecimal(item.totalBrandYear1T12),
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: formatNumOneDecimal(item.totalBrandYear1YTD),
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: formatNumOneDecimal(item.execQuantity),
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: `$${formatNum(item.execRevenue)}`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: `${formatNumOneDecimal(Math.floor(item?.salesGrowth * 100) / 100)}%`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: `${formatNumOneDecimal(Math.floor(item?.quantity * 100) / 100)}`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: `${formatNumOneDecimal(Math.floor(item?.priceGrowth * 100) / 100)}%`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                          {
                            v: `$${formatNum(Math.floor(item?.revenue * 100) / 100)}`,
                            t: 's',
                            s: { fill: { fgColor: { rgb: '8C8C8C' } }, font: { color: { rgb: 'FFFFFF' } } },
                          },
                        ]);

                        item.labels.forEach((label: any) => {
                          // add spaces to name to separate excel rows
                          columnData.push([
                            {
                              v: `   ${label.name as string}`,
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } }, alignment: { horizontal: 'rigth' } },
                            },
                            { v: '', t: 's', s: { fill: { fgColor: { rgb: 'C7C5C5' } } } },
                            {
                              v: formatNumOneDecimal(label.totalLabelYear2),
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: formatNumOneDecimal(label.totalLabelYear1T12),
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: formatNumOneDecimal(label.totalLabelYear1YTD),
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: formatNumOneDecimal(label.totalLabelExecQuantity),
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: `$${formatNum(label.totalLabelExecRevenue)}`,
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: `${formatNumOneDecimal(label.totalLabelSalesGrowth)}%`,
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: formatNumOneDecimal(label.totalLabelQuantity),
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: `${formatNumOneDecimal(label.totalLabelPriceGrowth)}%`,
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                            {
                              v: `$${formatNum(label.totalLabelRevenue)}`,
                              t: 's',
                              s: { fill: { fgColor: { rgb: 'C7C5C5' } } },
                            },
                          ]);

                          label.products.forEach((prod: any) => {
                            columnData.push([
                              {
                                v: `         ${prod.description as string}`,
                                t: 's',
                                s: { alignment: { horizontal: 'rigth' } },
                              },
                              { v: `$${formatNum(prod.price)}`, t: 's' },
                              { v: formatNumOneDecimal(prod.year2), t: 's' },
                              { v: formatNumOneDecimal(prod.year1T12), t: 's' },
                              { v: formatNumOneDecimal(prod.year1YTD), t: 's' },
                              { v: formatNumOneDecimal(prod.execQuantity), t: 's' },
                              { v: `$${formatNum(prod.execRevenue)}`, t: 's' },
                              {
                                v: prod.salesGrowth
                                  ? `${formatNumOneDecimal(Math.floor(prod.salesGrowth * 100) / 100)}%`
                                  : 0,
                                t: 's',
                              },
                              {
                                v: prod.quantity ? `${formatNumOneDecimal(Math.floor(prod.quantity * 100) / 100)}%` : 0,
                                t: 's',
                              },
                              {
                                v: prod.priceGrowth ? formatNumOneDecimal(Math.floor(prod.salesGrowth * 100) / 100) : 0,
                                t: 's',
                              },
                              { v: `$${formatNum(prod.revenue)}`, t: 's' },
                            ]);
                          });
                        });
                      });
                    }}
                  />
                  &nbsp;
                </label>
                <label className="form-label">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    aria-controls="dataTable"
                    placeholder="Search"
                    onChange={handleSearchChange}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="dont-alternate-row-color">
            <Table striped className="table-sm" bordered {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-gray">
                    {headerGroup.headers.map((column, index) => {
                      const canSort = column.id === 'product';
                      const columnProps = canSort
                        ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                        : { ...column.getHeaderProps() };
                      return (
                        <th
                          {...columnProps}
                          key={index}
                          colSpan={Number(column.width)}
                          onClick={() => {
                            customSort();
                          }}
                          className="table-head-font-size"
                        >
                          <div
                            className={
                              column.id === 'Growth' || column.id === 'Growth2'
                                ? 'd-flex align-items-center justify-content-center'
                                : 'd-flex align-items-center'
                            }
                          >
                            {column.render('Header')}
                            <span>
                              {canSort ? (
                                isDescSorted ? (
                                  <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                                ) : (
                                  <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                                )
                              ) : null}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i) => {
                  prepareRow(row);
                  const forecast = row.original;
                  return (
                    <React.Fragment key={i}>
                      <tr {...row.getRowProps()} id="1" className="table-body-font-size">
                        {row.cells.map((cell: any, index: number) => {
                          const brandColSpan = index === 1 || index === 6 ? 2 : index === 7 ? 3 : 1;
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              style={{ border: 'none' }}
                              className={'text-dark align-middle'}
                              colSpan={brandColSpan}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded
                        ? forecast.labels.map((label: any, index: number) => {
                            const totalLabelSalesDiffPercent = label.products.reduce(
                              (total: any, product: IProduct) => {
                                return total + Number(product.forecastProducts[0].salesDiffPercent);
                              },
                              0,
                            );
                            return (
                              <React.Fragment key={index}>
                                <tr
                                  className={` ${
                                    (totalLabelSalesDiffPercent || 0) < Number(mediumGrowthMin)
                                      ? lowBgColor
                                      : (totalLabelSalesDiffPercent || 0) > Number(mediumGrowthMax)
                                        ? highBgColor
                                        : mediumBgColor
                                  } text-dark table-body-font-size`}
                                >
                                  <td colSpan={2}>
                                    <div className="ms-3 d-flex align-items-center">
                                      {label.expandLabelRow ? (
                                        <FontAwesomeIcon
                                          onClick={() => expandLabelSubRow(label.labelId)}
                                          icon={faMinusCircle}
                                          className="ms-2 td-expand-button"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          onClick={() => expandLabelSubRow(label.labelId)}
                                          icon={faPlusCircle}
                                          className="ms-2 td-expand-button"
                                        />
                                      )}
                                      <p className="ms-2 m-0">{label.name}</p>
                                    </div>
                                  </td>
                                  <td colSpan={1}></td>
                                  <td colSpan={1}>{formatNum(label.totalLabelYear2, 1, 1)}</td>
                                  <td colSpan={1}>{formatNum(label.totalLabelYear1T12, 1, 1)}</td>
                                  <td colSpan={1}>{formatNum(label.totalLabelYear1YTD, 1, 1)}</td>
                                  <td colSpan={2}>
                                    Cases: {formatNum(label.totalLabelExecQuantity, 1, 1)}
                                    &nbsp;@ {Number(label.totalLabelExecSalesGrowth || '0')}%
                                    <br />
                                    Revenue: ${formatNum(label.totalLabelExecRevenue)}
                                  </td>
                                  <td colSpan={3}></td>
                                  <td colSpan={1}>${formatNum(label.totalLabelRevenue)}</td>
                                  <td colSpan={1}></td>
                                </tr>
                                {label.expandLabelRow &&
                                  label.products.map((product: IProduct) => {
                                    const forecastData = product.forecastProducts[0];
                                    const salesGrowthValue = checkNegativeAndDecimalBeforeCalulateRound(
                                      forecastData.salesGrowth,
                                    );
                                    const quantityValue = checkNegativeAndDecimalBeforeCalulateRound(
                                      forecastData.quantity,
                                    );
                                    const priceGrowthValue = checkNegativeAndDecimalBeforeCalulateRound(
                                      forecastData.priceGrowth,
                                    );
                                    return (
                                      <tr
                                        key={index}
                                        className={`${
                                          product.isDiscontinued
                                            ? 'bg-light text-secondary'
                                            : (forecastData.salesDiffPercent || 0) < Number(mediumGrowthMin)
                                              ? lowBgColor
                                              : (forecastData.salesDiffPercent || 0) > Number(mediumGrowthMax)
                                                ? highBgColor
                                                : mediumBgColor
                                        } ${!product.isDiscontinued && 'text-dark'} align-middle table-body-font-size`}
                                      >
                                        <td colSpan={2}>
                                          <p className="ms-5 m-0">
                                            {forecastData.isOverridden && (
                                              <FontAwesomeIcon icon={faSignOut} className="me-2" />
                                            )}
                                            {product.isDiscontinued && <OctogonalXLogo className="me-2" />}
                                            {product.isDiscontinued ? (
                                              <em>{product.description}</em>
                                            ) : (
                                              product.description
                                            )}
                                          </p>
                                        </td>
                                        <td colSpan={1}>
                                          {product.isDiscontinued ? (
                                            <em>${formatNum(Number(product.price))}</em>
                                          ) : (
                                            `$${formatNum(Number(product.price))}`
                                          )}
                                        </td>
                                        <td colSpan={1}>
                                          {product.isDiscontinued ? (
                                            <em>{formatNum((Number(product.year2), 1, 1))}</em>
                                          ) : (
                                            formatNum(Number(product.year2), 1, 1)
                                          )}
                                        </td>
                                        <td colSpan={1}>
                                          {product.isDiscontinued ? (
                                            <em>{formatNum((Number(product.year1T12), 1, 1))}</em>
                                          ) : (
                                            formatNum(Number(product.year1T12), 1, 1)
                                          )}
                                        </td>
                                        <td colSpan={1}>
                                          {product.isDiscontinued ? (
                                            <em>{formatNum((Number(product.year1YTD), 1, 1))}</em>
                                          ) : (
                                            formatNum(Number(product.year1YTD), 1, 1)
                                          )}
                                        </td>
                                        <td colSpan={1}>
                                          {product.isDiscontinued ? (
                                            <>
                                              <em>Cases: {formatNum(Number(product.execQuantity), 1, 1)}</em>
                                              <em>&nbsp;@ {Number(product.execSalesGrowth || '0')}%</em>
                                              <br />
                                              <em>Revenue: ${formatNum(Number(product.execRevenue))}</em>
                                            </>
                                          ) : (
                                            <>
                                              Cases: {formatNum(Number(product.execQuantity), 1, 1)}
                                              &nbsp;@ {Number(product.execSalesGrowth || '0')}%
                                              <br />
                                              Revenue: ${formatNum(Number(product.execRevenue))}
                                            </>
                                          )}
                                        </td>
                                        <td colSpan={3}>
                                          <div className="form-group">
                                            <label className="text-[12px] text-secondary">Sales Growth</label>
                                            <div className="d-flex flex-row">
                                              {isEditable && !isForecastFromParams ? (
                                                <input
                                                  disabled={product.isDiscontinued}
                                                  className="form-control text-[12px]"
                                                  type="text"
                                                  value={salesGrowthValue || ''}
                                                  onChange={(e) =>
                                                    validateOnChangeInput(
                                                      e,
                                                      forecastData.forecastProductId,
                                                      'salesGrowth',
                                                      handleChangeForecastProduct,
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <div className="text-[12px] d-flex align-items-center px-2">
                                                  {formatNum(forecastData.salesGrowth)}
                                                </div>
                                              )}
                                              <span className="input-group-text text-[12px]">%</span>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label className="text-[12px] text-secondary">Cases</label>
                                            <div className="d-flex flex-row">
                                              {isEditable && !isForecastFromParams ? (
                                                <input
                                                  className="form-control text-[12px]"
                                                  type="text"
                                                  value={quantityValue || ''}
                                                  onChange={(e) =>
                                                    validateOnChangeInput(
                                                      e,
                                                      forecastData.forecastProductId,
                                                      'quantity',
                                                      handleChangeForecastProduct,
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <div className="text-[12px] d-flex align-items-center px-2">
                                                  {formatNum(forecastData.quantity)}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label className="text-[12px] text-secondary">Price Growth</label>
                                            <div className="d-flex flex-row">
                                              {isEditable && !isForecastFromParams ? (
                                                <input
                                                  className="form-control text-[12px] w-[50px]"
                                                  type="text"
                                                  value={priceGrowthValue || ''}
                                                  onChange={(e) =>
                                                    validateOnChangeInput(
                                                      e,
                                                      forecastData.forecastProductId,
                                                      'priceGrowth',
                                                      handleChangeForecastProduct,
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <div className="text-[12px] d-flex align-items-center px-2">
                                                  {formatNum(forecastData.priceGrowth)}
                                                </div>
                                              )}
                                              <span className="input-group-text text-[12px]">%</span>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan={2}
                                          className={`${
                                            product.isDiscontinued
                                              ? 'bg-light'
                                              : (forecastData.revenueDiffPercent || 0) < Number(mediumGrowthMin)
                                                ? lowBgColor
                                                : (forecastData.revenueDiffPercent || 0) > Number(mediumGrowthMax)
                                                  ? highBgColor
                                                  : mediumBgColor
                                          }`}
                                        >
                                          ${formatNum(forecastData.revenue)}
                                        </td>
                                        <td colSpan={1}>
                                          <td style={tableItemStyle}>
                                            {isEditable && !isForecastFromParams && (
                                              <StageButton
                                                changedItem={forecastData}
                                                handleSave={() => {
                                                  handleSaveForecastProduct(forecastData);
                                                }}
                                              />
                                            )}
                                          </td>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </React.Fragment>
                            );
                          })
                        : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
              {
                <tfoot>
                  <tr>
                    <td colSpan={1}>
                      <strong>Grand Total</strong>
                    </td>
                    <td colSpan={2}></td>
                    <td colSpan={1}>
                      <strong className="text-dark">{formatNum(brandTotals.year2Total, 1, 1)}</strong>
                    </td>
                    <td colSpan={1}>
                      <strong className="text-dark">{formatNum(brandTotals.totalYear1T12, 1, 1)}</strong>
                    </td>
                    <td colSpan={1}>
                      <strong>{formatNum(brandTotals.totalYear1YTD, 1, 1)}</strong>
                    </td>
                    <td colSpan={1}>
                      <p className="m-0">
                        <strong>Cases: {formatNum(brandTotals.totalExcQuantity, 1, 1)}</strong>
                      </p>
                      <p className="m-0">
                        <strong>Revenue: ${formatNum(brandTotals.totalExcRevenue)}</strong>
                      </p>
                    </td>
                    <td colSpan={2}>
                      <p className="m-0">
                        <strong>
                          Sales Growth:{' '}
                          {brandTotals.totalExcQuantity
                            ? `${formatNum((brandTotals.totalQuantity / brandTotals.totalExcQuantity - 1) * 100)}%`
                            : ''}
                        </strong>
                      </p>
                      <p className="m-0">
                        <strong>Cases: {formatNum(brandTotals.totalQuantity)}</strong>
                      </p>
                      <p className="m-0">
                        <strong>Price Growth: {formatNum(brandTotals.totalPriceGrowth)}%</strong>
                      </p>
                    </td>
                    <td colSpan={3}>
                      <strong className="text-dark">${formatNum(brandTotals.totalRevenue)}&nbsp;</strong>
                    </td>
                    <td colSpan={1}></td>
                  </tr>
                </tfoot>
              }
            </Table>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {pageSize > count ? count : pageSize} of {count}
              </p>
            </div>
            <div className="col-md-6 mb-2">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => {
                        if (page > 1) {
                          setPage(page - 1);
                          if (page === currentStartPage) {
                            setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                          }
                        }
                      }}
                    >
                      <span aria-hidden="true">«</span>
                    </div>
                  </li>
                  <Pagination
                    totalPages={totalPages}
                    startPage={startPage}
                    currentStartPage={startPage}
                    pageSize={pageSize}
                    count={count}
                    page={page}
                    setPage={setPage}
                    setData={setProductForecasts}
                    initialData={initialData}
                  />
                  <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Next"
                      onClick={() => {
                        setPage(page + 1);
                        setCurrentStartPage(currentStartPage + 1);
                      }}
                    >
                      <span aria-hidden="true">»</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h3 className="text-dark mb-4">No Forecasts In the System</h3>
  );
};

const ProductForecast = () => {
  return (
    <ProjectionProvider>
      <ProductForecastView />
    </ProjectionProvider>
  );
};

export default ProductForecast;
