import React, { useEffect, useState } from 'react';
import BrandProjectionsTable from './BrandProjectionsTable';
import BrandFamilyProjectionsTable from './BrandFamilyProjectionsTable';
import ProductProjectionsTable from './ProductProjectionsTable';
import { ProjectionProvider, useProjection } from '../../providers/ProjectionProvider';
import useForecastModal from '../../hooks/useForecastModal';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import { hideLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import { ActiveProjectionWidget } from '../../components/projection/ActiveProjectionWidget';
import { useAuth } from '../../providers/AuthProvider';

const BrandForecastView: React.FC = () => {
  const { hasForecasts, forecastId } = useForecast();
  const { isCheckingLoggedState } = useAuth();
  const [projection, setProjection] = useState<any>(null);
  const { showForecastModal, setShowForecastModal } = useForecastModal();

  const projectionContext = useProjection();

  useEffect(() => {
    if (isCheckingLoggedState) {
      hideLoading();
    }
  }, []);

  useEffect(() => {
    if (hasForecasts && forecastId) {
      fetchProjection(forecastId);
    }
  }, [forecastId]);

  const fetchProjection = async (forecastId: string) => {
    projectionContext.isLoading = true;
    try {
      await projectionContext.handlers.fetch(forecastId);
      const projection = projectionContext.getProjection();
      setProjection(projection);
    } catch (error) {
      console.error(error);
    } finally {
      projectionContext.isLoading = false;
    }
  };

  const blurredStyle = {
    filter: projectionContext.isLoading ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };
  return (
    <>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <div className="row">
        <div className="col-12 col-md-12 col-lg-4">
          {projectionContext.isLoading && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '340px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <div style={blurredStyle}>
            <ActiveProjectionWidget
              isSummary={true}
              header={
                <>
                  <div className="d-flex justify-content-between">
                    <p className="text-lg fw-bold">{projection ? 'Projection Details' : 'No Active Projection'}</p>
                  </div>
                  {projection && (
                    <div className="d-flex justify-content-between">
                      <div className="">
                        Projected by:{' '}
                        {[projection?.projection?.user?.firstName, projection?.projection?.user?.lastName]
                          .filter(Boolean)
                          .join(' ')}
                      </div>
                    </div>
                  )}
                </>
              }
              projection={projection}
            />
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          {projectionContext.isLoading && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '700px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <div style={blurredStyle}>
            <BrandProjectionsTable projection={projection} />
          </div>
          {projectionContext.isLoading && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '700px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <div style={blurredStyle}>
            <BrandFamilyProjectionsTable projection={projection} />
          </div>
          {projectionContext.isLoading && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '700px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <div style={blurredStyle}>
            <ProductProjectionsTable projection={projection} />
          </div>
        </div>
      </div>
    </>
  );
};

const BrandForecast = () => {
  return (
    <ProjectionProvider>
      <BrandForecastView />
    </ProjectionProvider>
  );
};

export default BrandForecast;
