import React from 'react';
import { DropdownItem } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

export const StageButton = (props: any) => {
  const { changedItem, handleSave, title } = props;
  const [explanation, setExplanation] = React.useState(changedItem?.explanation || '');
  return (
    <Dropdown className="d-inline mx-2">
      <Dropdown.Toggle variant="secondary" size="sm">
        {title ? title : 'Stage'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div style={{ width: 350 }} className="px-4">
          <div className="py-1">Comments:</div>
          <div>
            <textarea
              placeholder=""
              rows={4}
              style={{ width: '100%' }}
              onChange={(evt) => {
                setExplanation(evt.target.value);
                changedItem.explanation = evt.target.value;
              }}
            >
              {explanation}
            </textarea>
            {!explanation && <div className="text-danger text-sm">Comments are required</div>}
          </div>
          <DropdownItem className="p-0">
            <button
              className="btn btn-secondary bt-sm float-end"
              type="button"
              disabled={!explanation}
              onClick={async () => {
                await handleSave();
              }}
            >
              Stage
            </button>
          </DropdownItem>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StageButton;
