import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { hideLoading, showLoading } from '../../../lib/uiService';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import AdminService from '../../../services/admin.service';
import LabelForm from './LabelForm';
import ProductForm from './ProductForm';
import ForecastService from '../../../services/forecast.service';
import Pagination from '../../../components/Pagination';

const ProductAndLabel: React.FC = () => {
  const { setError } = useErrorHandling();
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterType, setFilterType] = useState<{ [key: string]: string }>({ brand: '' });
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [startPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedObject, setSelectedObject] = useState<any>({ labelIsActive: true });
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [isLabel, setIsLabel] = useState(true);
  const [isEdition, setIsEdition] = useState(false);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [initialData, setInitialData] = useState([]);
  const [brandFamilies, setBrandFamilies] = useState([]);
  const [labels, setLabels] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [territoriesPrices, setTerritoriesPrices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (!isLabel)
      ForecastService.GetStateTerritories()
        .then(({ data }) => {
          const territoriesData = data.map((terr: any) => {
            return {
              ...terr,
              value: terr.territoryId,
              label: terr.name,
            };
          });
          const territoryIdSet = new Set(territoriesPrices.map((item: any) => item.territoryId));

          setTerritories(territoriesData.filter((item: any) => !territoryIdSet.has(item.territoryId)));
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
        });
  }, [isLabel, territoriesPrices]);

  const fetchProducts = async () => {
    showLoading();
    AdminService.GetBrandFamilysWithBrand()
      .then(({ data }) => {
        const brandFamiliesData = data.brands.map((brandFam: any) => {
          return {
            ...brandFam,
            value: brandFam.brandFamilyId,
            label: brandFam.brand.name + ' > ' + brandFam.name,
          };
        });
        setBrands(
          data.brands
            .map((item: any) => item.brand.name)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index),
        );
        setBrandFamilies(brandFamiliesData.sort((a: any, b: any) => a.label.localeCompare(b.label)));
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });

    AdminService.GetLabelsWithBrand()
      .then(({ data }) => {
        const labelsData = data.labels.map((label: any) => {
          return {
            ...label,
            labelName: label.name,
            value: label.labelId,
            label: label.brandFamily.brand.name + ' > ' + label.name,
          };
        });
        setLabels(labelsData.sort((a: any, b: any) => a.label.localeCompare(b.label)));
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
    try {
      const { data } = await AdminService.GetProductsAndLabels({
        page,
        pageSize,
      });
      const key = Object.keys(filterType)[0];
      const value = filterType[key].toLowerCase();
      const finalData = isFiltered
        ? data.products.filter((item: any) => item[key].toLowerCase().includes(value))
        : data.products.slice((page - 1) * pageSize, page * pageSize);
      setProducts(finalData);
      setInitialData(data.products);
      setCount(isFiltered ? finalData.length : data.count);
      setTotalPages(Math.ceil((isFiltered ? finalData.length : data.count) / pageSize));
      hideLoading();
    } catch (error: any) {
      console.log(error);
      setError({ status: error.response.status || 500 });
      hideLoading();
    }
  };

  const getNonRepeatedSubstring = (str1: string, str2: string) => {
    const minLength = Math.min(str1.length, str2.length);
    let commonPrefixLength = 0;

    for (let i = 0; i < minLength; i++) {
      if (str1[i] === str2[i]) {
        commonPrefixLength++;
      } else {
        break;
      }
    }

    if (str1.length > str2.length) {
      return str1.substring(commonPrefixLength).trim();
    } else {
      return str2.substring(commonPrefixLength).trim();
    }
  };

  const setProductData = (rowData: any) => {
    showLoading();
    AdminService.GetProductPrices(rowData.productId)
      .then(({ data }) => {
        const productSpecificName = getNonRepeatedSubstring(rowData.product, rowData.label);
        setSelectedObject({
          ...rowData,
          productSpecificName,
          nationalPrice: rowData.productPrice?.price || 0,
        });
        setTerritoriesPrices(data.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setIsLabel(false);
        setIsEdition(true);
        hideLoading();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
        hideLoading();
      });
  };

  const columns: any = useMemo<ColumnDef<any, unknown>[]>(
    () => [
      {
        accessorKey: 'brand',
        cell: (info) => info.getValue(),
        header: () => 'Brand',
        meta: {
          filterVariant: 'select',
        },
        size: 120,
      },
      {
        accessorKey: 'brandFamily',
        cell: (info) => info.getValue(),
        header: () => 'Brand Family',
        isFilterFocused: false,
        size: 200,
      },
      {
        accessorKey: 'label',
        header: () => 'Label',
        isFilterFocused: false,
        size: 200,
        cell: (info: any) => {
          const rowData = info.row.original;
          return (
            <a
              className="text-primary"
              onClick={() => {
                setSelectedObject({
                  ...rowData,
                  labelSpecificName: getNonRepeatedSubstring(rowData.label, rowData.brand),
                });
                setErrorMessage('');
                setTerritoriesPrices([]);
                setIsLabel(true);
                setIsEdition(true);
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            >
              {info.getValue()}
            </a>
          );
        },
      },
      {
        accessorKey: 'product',
        header: () => 'Product',
        isFilterFocused: false,
        size: 200,
        cell: (info: any) => {
          const rowData = info.row.original;
          return (
            <a
              className="text-primary"
              onClick={() => {
                setErrorMessage('');
                setProductData(rowData);
              }}
            >
              {info.getValue()}
            </a>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: useMemo(() => products, [products]),
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const Filter = ({ column }: { column: any }) => {
    const columnFilterValue = column.getFilterValue();
    const { filterVariant } = column.columnDef.meta ?? {};
    const [inputValue, setInputValue] = useState(columnFilterValue ?? '');
    const [isFocused, setIsFocused] = useState(column.columnDef.isFilterFocused);
    return filterVariant === 'select' ? (
      <select
        className="w-36 border shadow rounded"
        onChange={(e) => {
          column.setFilterValue(e.target.value);
          if (e.target.value !== '') {
            setFilterType({ [column.id]: String(e.target.value).toLowerCase() });
            const filteredProducts = initialData.filter((item: any) => item.brand === e.target.value);
            setCount(filteredProducts.length);
            setTotalPages(Math.ceil(filteredProducts.length / pageSize));
            setProducts(filteredProducts);
            setFilteredData(filteredProducts);
            setIsFiltered(true);
          } else {
            setIsFiltered(false);
            setProducts(initialData);
            setCount(initialData.length);
            setTotalPages(Math.ceil(initialData.length / pageSize));
          }
          setPageSize(100);
          setPage(1);
          setCurrentStartPage(1);
        }}
        value={columnFilterValue?.toString()}
      >
        <option value="">All</option>
        {brands.map((item: string, index: number) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    ) : (
      <input
        onFocus={() => {
          setIsFocused(true);
          column.columnDef.isFilterFocused = true;
        }}
        onBlur={() => {
          setIsFocused(false);
          column.columnDef.isFilterFocused = false;
        }}
        autoFocus={isFocused}
        className="w-36 border shadow rounded"
        onChange={(e) => {
          const value = e.target.value;
          column.setFilterValue(value);
          setInputValue(value);
          if (value !== '') {
            setFilterType({ [column.id]: String(value).toLowerCase() });
            const filteredProducts = initialData.filter((item: any) =>
              String(item[column.id]).toLowerCase().includes(String(value).toLowerCase()),
            );
            setCount(filteredProducts.length);
            setFilteredData(filteredProducts);
            setTotalPages(Math.ceil(filteredProducts.length / pageSize));
            setProducts(filteredProducts);
            setIsFiltered(true);
          } else {
            setProducts(initialData);
            setCount(initialData.length);
            setIsFiltered(false);
            setTotalPages(Math.ceil(initialData.length / pageSize));
          }
          setPageSize(100);
          setPage(1);
          setCurrentStartPage(1);
        }}
        placeholder={`Search...`}
        type="text"
        value={(inputValue ?? '') as string}
      />
    );
  };

  return (
    <>
      <h3 className="text-dark mb-4">Labels & Products</h3>
      {isLabel ? (
        <LabelForm
          isEdit={isEdition}
          setIsLabel={setIsLabel}
          labels={labels}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setIsEdition={setIsEdition}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          brandFamilies={brandFamilies}
          fetchProducts={fetchProducts}
        />
      ) : (
        <ProductForm
          isEdit={isEdition}
          setIsEdition={setIsEdition}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          labels={labels}
          territories={territories}
          setTerritoriesPrices={setTerritoriesPrices}
          territoriesPrices={territoriesPrices}
          fetchProducts={fetchProducts}
        />
      )}
      <div className="card shadow mb-[44px]">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="1em"
              height="1em"
              fill="currentColor"
              className="me-2"
              style={{ fontSize: '30px' }}
            >
              <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"></path>
            </svg>
            Product List
          </p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div className="dataTables_length" aria-controls="dataTable">
                <label className="form-label">
                  Show&nbsp;
                  <select
                    value={pageSize}
                    className="d-inline-block form-select form-select-sm"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      setPage(1);
                      setCurrentStartPage(1);
                      setTotalPages(Math.ceil(count / Number(e.target.value)));
                      setProducts(
                        isFiltered
                          ? filteredData.slice(0, Number(e.target.value))
                          : initialData.slice(0, Number(e.target.value)),
                      );
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100" selected>
                      100
                    </option>
                  </select>
                  &nbsp;
                </label>
              </div>
            </div>
          </div>
          <div>
            <Table striped className="table-sm" bordered>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan} style={{ width: header.column.columnDef.size }}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: <FontAwesomeIcon icon={faSortUp} className="ms-2" />,
                                  desc: <FontAwesomeIcon icon={faSortDown} className="ms-2" />,
                                }[header.column.getIsSorted() as string] ?? (
                                  <FontAwesomeIcon icon={faSort} className="ms-2" />
                                )}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter column={header.column} />
                                </div>
                              ) : null}
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className="table-body-font-size">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id} className={'text-dark align-middle py-2'}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {pageSize > count ? count : pageSize} of {count}
              </p>
            </div>
            <div className="col-md-6 mb-2">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => {
                        if (page > 1) {
                          const newPage = page - 1;
                          setPage(newPage);
                          const indexOfLastItem = newPage * pageSize;
                          const indexOfFirstItem = indexOfLastItem - pageSize;
                          setProducts(
                            isFiltered
                              ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
                              : initialData.slice(indexOfFirstItem, indexOfLastItem),
                          );
                          if (page === currentStartPage) {
                            setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                          }
                        }
                      }}
                    >
                      <span aria-hidden="true">«</span>
                    </div>
                  </li>
                  <Pagination
                    currentStartPage={currentStartPage}
                    totalPages={totalPages}
                    startPage={startPage}
                    pageSize={pageSize}
                    count={count}
                    page={page}
                    setPage={setPage}
                    setData={setProducts}
                    initialData={isFiltered ? filteredData : initialData}
                    setCurrentStartPage={setCurrentStartPage}
                  />
                  <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Next"
                      onClick={() => {
                        const newPage = page + 1;
                        setPage(newPage);
                        const indexOfLastItem = newPage * pageSize;
                        const indexOfFirstItem = indexOfLastItem - pageSize;
                        setProducts(
                          isFiltered
                            ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
                            : initialData.slice(indexOfFirstItem, indexOfLastItem),
                        );
                        setCurrentStartPage(currentStartPage + 1);
                      }}
                    >
                      <span aria-hidden="true">»</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAndLabel;
