import React from 'react';
import { formatNum, formatNumOneDecimal } from '../utils/formatNum';

const ForecastInfoCard = ({
  forecastBudgetData,
  currentForecastYear,
}: {
  forecastBudgetData: any;
  currentForecastYear: number;
}) => {
  const casesGrowth =
    ((Number(forecastBudgetData.cases) - Number(forecastBudgetData.extrapolatedCases || forecastBudgetData.prevcases)) /
      Number(forecastBudgetData.extrapolatedCases || forecastBudgetData.prevcases)) *
    100;
  const revenueGrowth =
    ((Number(forecastBudgetData.revenue) -
      Number(forecastBudgetData.extrapolatedRevenue || forecastBudgetData.prevrevenue)) /
      Number(forecastBudgetData.extrapolatedRevenue || forecastBudgetData.prevrevenue)) *
    100;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{currentForecastYear - 1} Shipments</h4>
              <h4 className="card-title" style={{ fontSize: '14px' }}>
                {forecastBudgetData?.extrapolatedCases
                  ? 'Actual: '
                  : forecastBudgetData?.isForecasted
                    ? 'Forecasted: '
                    : null}
                {formatNumOneDecimal(Number(forecastBudgetData.prevcases))}
              </h4>
              {forecastBudgetData?.extrapolatedCases ? (
                <h4 className="card-title" style={{ fontSize: '14px' }}>
                  Projected: {formatNumOneDecimal(Number(forecastBudgetData.extrapolatedCases))}
                </h4>
              ) : null}
              <h6 className="text-muted card-subtitle mb-2"></h6>
              <div className="mb-0">
                <span
                  className={`badge bg-${Number(forecastBudgetData.casesGrowthForPrevYear) >= 0 ? 'primary' : 'danger'} me-2 bg-success`}
                >
                  {formatNum(Number(forecastBudgetData.casesGrowthForPrevYear), 1, 1)}%
                </span>
                <span>Growth over last year</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{currentForecastYear - 1} Revenue</h4>
              <h4 className="card-title" style={{ fontSize: '14px' }}>
                {forecastBudgetData?.extrapolatedRevenue
                  ? 'Actual: '
                  : forecastBudgetData?.isForecasted
                    ? 'Forecasted: '
                    : null}{' '}
                ${formatNum(Number(forecastBudgetData.prevrevenue))}
              </h4>
              {forecastBudgetData?.extrapolatedRevenue ? (
                <h4 className="card-title" style={{ fontSize: '14px' }}>
                  Projected: ${formatNumOneDecimal(Number(forecastBudgetData.extrapolatedRevenue))}
                </h4>
              ) : null}
              <h6 className="text-muted card-subtitle mb-2"></h6>
              <div className="mb-0">
                <span
                  className={`badge bg-${Number(forecastBudgetData.revenueGrowthForPrevYear) >= 0 ? 'primary' : 'danger'} me-2 bg-success`}
                >
                  {formatNum(Number(forecastBudgetData.revenueGrowthForPrevYear), 1, 1)}%
                </span>
                <span>Growth over last year</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{currentForecastYear} Forecasted Shipments</h4>
              <h4 className="card-title" style={{ fontSize: '14px' }}>
                {formatNumOneDecimal(Number(forecastBudgetData.cases))}
              </h4>
              {forecastBudgetData?.extrapolatedCases ? (
                <h4 className="card-title text-white" style={{ fontSize: '14px' }}>
                  .
                </h4>
              ) : null}
              <h6 className="text-muted card-subtitle mb-2"></h6>
              <div className="mb-0">
                <span className={`badge bg-${Number(casesGrowth) >= 0 ? 'primary' : 'danger'} me-2 bg-success`}>
                  {formatNum(casesGrowth === Infinity ? 100 : casesGrowth, 1, 1)}%
                </span>
                <span>Growth over last year</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{currentForecastYear} Forecasted Revenue</h4>
              <h4 className="card-title" style={{ fontSize: '14px' }}>
                ${formatNum(Number(forecastBudgetData.revenue))}
              </h4>
              {forecastBudgetData?.extrapolatedRevenue ? (
                <h4 className="card-title text-white" style={{ fontSize: '14px' }}>
                  .
                </h4>
              ) : null}
              <h6 className="text-muted card-subtitle mb-2"></h6>
              <div className="mb-0">
                <span className={`badge bg-${Number(revenueGrowth) >= 0 ? 'primary' : 'danger'} me-2 bg-success`}>
                  {formatNum(revenueGrowth === Infinity ? 100 : revenueGrowth, 1, 1)}%
                </span>
                <span>Growth over last year</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForecastInfoCard;
