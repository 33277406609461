import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';

import { forecastSection, adminSection, analysisSection } from '../components/sidebar/dashboardItems';
import { useAuth } from '../providers/AuthProvider';
import { ToastContainer } from 'react-toastify';

const MainLayout: any = ({ children, isAdmin }: { children: any; isAdmin: boolean }) => {
  const { isLoggedIn, role, isCheckingLoggedState } = useAuth();
  const logged = localStorage.getItem('loggingState');
  if ((!isLoggedIn && !isCheckingLoggedState) || logged !== 'loggedIn') {
    return <Navigate to="/login" replace={true} />;
  }
  const location = useLocation();
  localStorage.setItem('path', location.pathname);

  if (isAdmin && isLoggedIn && !isCheckingLoggedState && role !== 'Administrator') {
    return <Navigate to="/403" replace={true} state={{ adminCheck: true }} />;
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar
          items={
            role === 'Administrator'
              ? [forecastSection, analysisSection, adminSection]
              : [forecastSection, analysisSection]
          }
        />
        <Main>
          <Navbar />
          <Content>
            <ToastContainer position="top-right" autoClose={3000} />
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default MainLayout;
