const pick = (obj: any, keys: string[]) => {
  return keys.reduce((acc: any, key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

const formatRecordProduct = (item: any) => {
  item.quantity = Number(item.quantity) || 0;
  item.quantity9L = Number(item.quantity9L) || 0;
  item.revenue = Number(item.revenue) || 0;
  item.price = Number(item.price) || 0;
  item.salesGrowth = Number(item.salesGrowth) || 0;
  item.priceGrowth = Number(item.priceGrowth) || 0;
  return item;
};

const formatRecordBudget = (item: any) => {
  item.quantity = Number(item.quantity) || 0;
  item.quantity9L = Number(item.quantity9L) || 0;
  item.revenue = Number(item.revenue) || 0;
  item.salesGrowth = Number(item.salesGrowth) || 0;
  item.priceGrowth = Number(item.priceGrowth) || 0;
  return item;
};

export class ProjectionModel {
  projectionId: any = '';
  projection: any;
  forecastBudgetsMap: any = {};
  forecastProductsMap: any = {};

  constructor(projection: any) {
    this.projection = projection;
    this.projectionId = projection.projectionId;
    this.init();
  }

  init() {
    this.projection?.forecastBudgetProjections?.forEach?.((item: any) => {
      this.forecastBudgetsMap[item.forecastBudgetId] = item;
    });
    this.projection?.forecastProductProjections?.forEach?.((item: any) => {
      this.forecastProductsMap[item.forecastProductId] = item;
    });
  }

  getBrandChanges() {
    const brandChanges =
      this.projection?.forecastBudgetProjections?.filter?.((item: any) => {
        return item?.forecastBudget?.budgetType === 'brand' && item.sourceChangeTypeId === 1;
      }) ?? [];
    return brandChanges;
  }

  getBrandFamilyChanges() {
    return (
      this.projection?.forecastBudgetProjections?.filter?.(
        (item: any) => item?.forecastBudget?.budgetType === 'brandFamily' && item.sourceChangeTypeId === 1,
      ) ?? []
    );
  }

  getProductChanges() {
    return this.projection?.forecastProductProjections?.filter?.((item: any) => item.sourceChangeTypeId === 1) ?? [];
  }

  findForecastProductChange(forecastProduct: any) {
    const forecastProductId = forecastProduct?.forecastProductId ?? forecastProduct;
    if (!forecastProductId) return;
    const found = this.forecastProductsMap?.[forecastProductId];
    return found;
  }
  findForecastBudgetChange(forecastBudget: any) {
    const forecastBudgetId = forecastBudget?.forecastBudgetId ?? forecastBudget;
    if (!forecastBudgetId) return;
    const found = this.forecastBudgetsMap?.[forecastBudgetId];
    return found;
  }

  applyToForecastDataItem(item: any) {
    if (Array.isArray(item)) {
      item.forEach((i: any) => {
        this.applyToForecastDataItem(i);
      });
      return;
    }
    const foundProduct = this.findForecastProductChange(item);
    if (foundProduct) {
      const values = formatRecordProduct(
        pick(foundProduct, ['quantity', 'quantity9L', 'revenue', 'price', 'salesGrowth', 'priceGrowth', 'explanation']),
      );
      Object.assign(item, {
        ...values,
        ...(foundProduct.sourceChangeTypeId === 1 ? { isOverridden: true } : {}),
      });
    }
    const foundBudget = this.findForecastBudgetChange(item);
    if (foundBudget) {
      const values = formatRecordBudget(
        pick(foundBudget, ['quantity', 'quantity9L', 'revenue', 'salesGrowth', 'priceGrowth', 'explanation']),
      );
      Object.assign(item, {
        ...values,
        ...(foundBudget.sourceChangeTypeId === 1 ? { isOverridden: true } : {}),
      });
    }
  }

  applyToForecastData(data: any) {
    data?.forEach?.((item: any) => {
      item.labels?.forEach?.((label: any) => {
        label?.products.forEach?.((product: any) => {
          this.applyToForecastDataItem(product.forecastProducts);
        });
      });
      this.applyToForecastDataItem(item.forecastBudget);
      this.applyToForecastDataItem(item);
    });
  }
}

export default ProjectionModel;
