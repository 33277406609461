import React, { useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import ForecastHistoryWidget from '../../components/history/ForecastHistoryWidget';
import { HistoryProvider } from '../../providers/HistoryProvider';

import { useAuth } from '../../providers/AuthProvider';
import { useProjection } from '../../providers/ProjectionProvider';
import ShareButton from './ShareButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import { useForecast } from '../../providers/ForecastProvider';

export const ActiveProjectionWidget = ({
  header,
  isSummary,
  projection,
}: {
  header: any;
  isSummary?: boolean;
  projection: any;
}) => {
  const auth = useAuth();
  const isAdmin = auth.isAdmin();
  const [notes, setNotes] = React.useState<string>('');
  const [isCommit, setIsCommit] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isForecastFromParams } = useForecast();

  useEffect(() => {
    if (copied) toast.success('The link has been copied to your clipboard');
    setCopied(false);
  }, [copied, setCopied]);

  const projectionContext = useProjection();
  projection = projectionContext.getProjection() || projection;

  return (
    <div className="card shadow p-0 mb-[40px]">
      <div className="card-header">{header}</div>
      <div className="" style={{ position: 'absolute', right: 8, top: 12 }}>
        <HistoryProvider>
          <ForecastHistoryWidget />
        </HistoryProvider>
      </div>

      <div className="card-body d-flex justify-content-between">
        <div>
          <div className="row">
            {!!projection && projection?.getBrandChanges().length !== 0 ? (
              <span
                onClick={() => navigate(`/forecast/projection/${projectionContext.projectionId}`)}
                className="text-primary text-decoration-underline cursor-pointer"
              >
                <Badge pill bg="primary" className="me-1">
                  {projection?.getBrandChanges().length}
                </Badge>
                Brand {projection?.getBrandChanges().length === 1 ? 'Change' : 'Changes'}
              </span>
            ) : (
              <div>
                <span className="text-primary">
                  <Badge pill bg="primary" className="me-1">
                    0
                  </Badge>
                  Brand Changes
                </span>
              </div>
            )}
          </div>
          <div className="row">
            {!!projection && projection?.getBrandFamilyChanges().length !== 0 ? (
              <span
                onClick={() => navigate(`/forecast/projection/${projectionContext.projectionId}`)}
                className="text-primary text-decoration-underline cursor-pointer"
              >
                <Badge pill bg="primary" className="me-1">
                  {projection?.getBrandFamilyChanges().length}
                </Badge>
                Brand Family {projection?.getBrandFamilyChanges().length === 1 ? 'Change' : 'Changes'}
              </span>
            ) : (
              <span className="text-primary">
                <Badge pill bg="primary" className="me-1">
                  0
                </Badge>
                Brand Family Changes
              </span>
            )}
          </div>
          <div className="row">
            {!!projection && projection?.getProductChanges().length !== 0 ? (
              <span
                onClick={() => navigate(`/forecast/projection/${projectionContext.projectionId}`)}
                className="text-primary text-decoration-underline cursor-pointer"
              >
                <Badge pill bg="primary" className="me-1">
                  {projection?.getProductChanges().length}
                </Badge>
                Product {projection?.getProductChanges().length === 1 ? 'Change' : 'Changes'}
              </span>
            ) : (
              <span className="text-primary">
                <Badge pill bg="primary" className="me-1">
                  0
                </Badge>
                Product Changes
              </span>
            )}
          </div>
        </div>
        <div>
          {!!projection && (
            <div>
              {!isSummary && (
                <button
                  disabled={isForecastFromParams}
                  onClick={() => navigate(`/forecast/projection/${projectionContext.projectionId}`)}
                  className="btn btn-sm btn-primary mx-2"
                >
                  Details
                </button>
              )}
              <ShareButton text={`/forecast/projection/${projectionContext.projectionId}`} setCopied={setCopied} />
            </div>
          )}
        </div>
      </div>
      {projection && (
        <div className="card-footer">
          <div className="row mb-4 px-4">
            <textarea
              disabled={isForecastFromParams}
              placeholder="Changeset Notes"
              defaultValue={projection?.projection.notes}
              onChange={(evt) => setNotes(evt.target.value)}
            ></textarea>
          </div>
          <div className="d-flex justify-content-between">
            <div className="">
              <button
                disabled={isForecastFromParams}
                className="btn btn-dark btn-sm"
                type="button"
                onClick={async () => {
                  setIsCommit(false);
                  setIsOpen(true);
                }}
              >
                Discard
              </button>
            </div>
            <div className="">
              {isAdmin ? (
                <button
                  disabled={isForecastFromParams || notes.trim().length === 0}
                  className="btn btn-primary btn-sm justify-content-end float-end"
                  type="button"
                  onClick={async () => {
                    setIsCommit(true);
                    setIsOpen(true);
                  }}
                >
                  Commit
                </button>
              ) : (
                <div></div>
              )}
              <button
                disabled={isForecastFromParams}
                className="btn btn-primary btn-sm justify-content-end float-end mx-2"
                type="button"
                onClick={async () => {
                  await projectionContext.handlers.saveNotes({ notes });
                  toast.success('Successfully Saved Notes');
                }}
              >
                Save Notes
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
        <Modal.Header closeButton>Confirm Selection</Modal.Header>
        <Modal.Body className="text-center m-3">
          {isCommit ? (
            <h6>Are you sure you want to commit the changes?</h6>
          ) : (
            <h6>Are you sure you want to discard the changes?</h6>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={async () => {
              if (isCommit) {
                await projectionContext.handlers.commit({ notes });
                setIsOpen(false);
                toast.success(`Successfully ${isCommit ? 'Committed' : 'Discarded'} Projection`);
              } else {
                await projectionContext.handlers.discard();
                setIsOpen(false);
                toast.success(`Successfully ${isCommit ? 'Committed' : 'Discarded'} Projection`);
              }
            }}
          >
            {isCommit ? 'Commit' : 'Discard'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActiveProjectionWidget;
