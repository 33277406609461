import React, { useEffect, useState } from 'react';
import { useForecast } from '../../providers/ForecastProvider';
import Select from 'react-select';

interface ISelectItem {
  name: string;
  forecastId?: string;
  territoryId?: string;
  forecastCalendarId?: string;
  year?: number;
}

interface INavbarSelect {
  items: ISelectItem[];
  placeholder: string;
  scope: 'territory' | 'forecast' | 'timePeriod';
  disabled?: boolean;
  isParamData: boolean;
}

const NavbarSelect: React.FC<INavbarSelect> = (props) => {
  const { items, placeholder, scope, disabled, isParamData } = props;
  const [selectedItem, setSelectedItem] = useState<string>('');
  const {
    setForecastId,
    forecastId,
    forecastCalendarId,
    setTerritoryId,
    forecastUpdated,
    setForecastUpdated,
    hasForecasts,
    setForecastCalendarId,
    setCurrentForecastYear,
  } = useForecast();

  useEffect(() => {
    if (!isParamData) {
      const prevValue = localStorage.getItem(scope) || '';
      const prevYear = localStorage.getItem('curYear');
      if (prevYear) setCurrentForecastYear(Number(prevYear));
      setSelectedItem(prevValue);
      if (scope === 'forecast') setForecastId(prevValue);
      if (scope === 'territory') setTerritoryId(prevValue);
      if (scope === 'timePeriod') setForecastCalendarId(prevValue);
    } else {
      if (scope === 'forecast') setSelectedItem(forecastId);
      if (scope === 'timePeriod') setSelectedItem(forecastCalendarId);
    }
  }, [forecastUpdated, isParamData, forecastCalendarId]);

  const handleChange = ({ value }: { value: string }) => {
    const curYear = new Date().getFullYear();
    if (!value) {
      return;
    } else {
      setSelectedItem(value);
      if (scope === 'forecast') {
        setForecastId(value);
        // clean forecast calendar
        /* setCurrentForecastYear(curYear);
        localStorage.setItem('curYear', `${curYear}`); */
        setForecastCalendarId('');
        localStorage.setItem('timePeriod', '');
        localStorage.setItem('forecast', String(value));
      }
      if (scope === 'territory') setTerritoryId(value);
      if (scope === 'timePeriod') {
        const currForecastCalendar = items.filter((item) => item.forecastCalendarId === value);
        const yearValue =
          currForecastCalendar && currForecastCalendar[0]?.year ? currForecastCalendar[0].year : curYear;
        setCurrentForecastYear(yearValue);
        localStorage.setItem('curYear', String(yearValue));
        localStorage.setItem('previousCurrentYear', String(yearValue));
        setForecastCalendarId(value);
      }
      localStorage.setItem(scope === 'forecast' ? 'previousForecast' : 'previousTimePeriod', value);
      setForecastUpdated(true);
    }
  };

  const options = items?.map((item) => ({
    value: scope === 'forecast' ? item.forecastId : scope === 'territory' ? item.territoryId : item.forecastCalendarId,
    label: item.name,
  }));

  const selectedOption = options?.find((option) => option.value === selectedItem) || null;
  return (
    <div>
      <Select
        isDisabled={!hasForecasts || disabled}
        isSearchable={true}
        value={selectedOption}
        onChange={(option: any) => handleChange(option)}
        placeholder={placeholder}
        options={options}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '180px',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '27px',
            borderColor: '#e5e5ea',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
            alignItems: 'normal',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: '1000',
          }),
        }}
      />
    </div>
  );
};

export default NavbarSelect;
