import React from 'react';
import { useLocation } from 'react-router-dom';
const page403: React.FC = () => {
  const location = useLocation();
  const state = location?.state;

  return (
    <div className="main d-flex w-100 justify-content-center">
      <div className="d-flex flex-column container">
        <div className="h-100 row">
          <div className="mx-auto d-table h-100 col-xl-5 col-lg-6 col-md-8 col-sm-10">
            <div className="d-table-cell align-middle">
              <div className="text-center">
                <h1 className="display-1 fw-bold">403</h1>
                <p className="h2">Unauthorized</p>
                <p className="lead fw-normal mt-3 mb-4">You do not have access to this page</p>
                <a href={state && state?.adminCheck === true ? '/forecast/dashboard' : '/login'}>
                  <button type="button" className="btn btn-primary btn-lg">
                    {state && state?.adminCheck === true ? 'Go to Dashboard' : 'Log in'}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default page403;
