import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AdminService from '../../../services/admin.service';
import { hideLoading, showLoading } from '../../../lib/uiService';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCircleXmark, faQuestionCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Define the validation schema with Yup
const ProductSchema = Yup.object().shape({
  productSpecificName: Yup.string().required('A specific name must be inputed for the product.'),
  nationalPrice: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'National Price must be greater than 0')
    .required('National Price is required'),
  federalExciseTax: Yup.number()
    .nullable()
    .notRequired()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .min(0, 'Value must be greater than or equal to 0')
    .nullable(),
  quantity: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Quantity must be greater than 0')
    .required('Quantity is required'),
  volume: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Volume must be greater than 0')
    .required('Volume is required'),
});

const ProductForm = ({
  selectedObject,
  fetchProducts,
  setSelectedObject,
  isEdit,
  setIsEdition,
  labels,
  territories,
  territoriesPrices,
  setTerritoriesPrices,
}: {
  selectedObject: any;
  fetchProducts: () => void;
  setSelectedObject: (x: any) => void;
  setIsEdition: (x: any) => void;
  isEdit: boolean;
  labels: any;
  territories: any;
  territoriesPrices: any;
  setTerritoriesPrices: (x: any) => void;
}) => {
  const { setError } = useErrorHandling();
  const [deletedTerritoryPrices, setDeletedTerritoryPrices] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const scrollableRef: any = useRef(null);

  const handlePriceChange = (territoryId: string, newPrice: string) => {
    const existingChange = territoriesPrices.find((change: any) => change.territoryId === territoryId);
    if (existingChange) {
      const updatedChanges = territoriesPrices.map((change: any) =>
        change.territoryId === territoryId ? { ...change, price: newPrice } : change,
      );
      setTerritoriesPrices(updatedChanges);
    } else {
      setTerritoriesPrices([...territoriesPrices, { territoryId, price: newPrice }]);
    }
  };

  const parseProductSpecificName = (input: string) => {
    const regex = /(\d+)\/(\d+)[mM][lL]|(\d+)[mM][lL]\/(\d+)/;
    const match = input.match(regex);
    if (match) {
      const quantity = match[1] || match[4];
      const volume = match[2] || match[3];
      return { quantity: parseInt(quantity, 10), volume: parseInt(volume, 10) };
    }
    return { quantity: '', volume: '' };
  };

  const selectedOptionLabel = labels?.find((option: any) => option.value === selectedObject?.labelId) || null;

  return (
    <div className="card shadow mb-[40px] p-0">
      <div className="card-header d-flex justify-content-between">
        <h5 className="text-primary m-0 fw-bold pt-1 pb-1 d-flex align-items-center">
          <FontAwesomeIcon icon={faBoxOpen} className="me-2" style={{ fontSize: '30px' }} />
          {isEdit ? 'Edit' : 'Create'} Product
        </h5>
        {isEdit && (
          <div className="pt-2 px-3 cursor-pointer">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => {
                setIsEdition(false);
                setSelectedObject({ label: selectedObject.label, labelId: selectedObject.labelId });
                setTerritoriesPrices([]);
              }}
            />
          </div>
        )}
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={{
            productSpecificName: selectedObject?.productSpecificName || '',
            nationalPrice: selectedObject?.nationalPrice || '',
            federalExciseTax: selectedObject?.federalExciseTax || '',
            quantity: selectedObject?.quantity || '',
            volume: selectedObject?.volume || '',
            bbpProductNumber: selectedObject?.bbpProductNumber || '',
            labelId: selectedObject?.labelId || '',
            label: selectedObject?.label || '',
            labelIsActive: selectedObject?.labelIsActive || false,
          }}
          validationSchema={ProductSchema}
          onSubmit={(values) => {
            const territoryHasNoPrice = territoriesPrices.some((item: any) => Number(item.price) <= 0);
            if (territoryHasNoPrice) {
              console.log(territoryHasNoPrice);
              setErrorMessage('All territories must have a price greater than 0.');
            } else {
              setErrorMessage('');
              showLoading();
              if (isEdit) {
                AdminService.UpdateProduct({ ...selectedObject, ...values }, territoriesPrices, deletedTerritoryPrices)
                  .then(() => {
                    fetchProducts();
                    setSelectedObject({});
                    setTerritoriesPrices([]);
                    toast.success('Product successfully Updated');
                  })
                  .catch((error: any) => {
                    console.log(error);
                    setError({ status: error.response.status || 500 });
                    hideLoading();
                  });
              } else {
                AdminService.CreateProduct({ ...selectedObject, ...values }, territoriesPrices)
                  .then(() => {
                    fetchProducts();
                    setSelectedObject({});
                    setTerritoriesPrices([]);
                    toast.success('Product successfully Created');
                  })
                  .catch((error: any) => {
                    console.log(error);
                    setError({ status: error.response.status || 500 });
                    hideLoading();
                  });
              }
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="col ps-3 col-3" style={{ borderRight: '1px solid #EEEEEE' }}>
                  <label className="form-label">Description</label>
                  <div className="d-flex">
                    <Field name="label" disabled={true} type="text" className="w-100" />
                    <Field
                      name="productSpecificName"
                      type="text"
                      className="w-50"
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        setFieldValue('productSpecificName', inputValue);
                        const { quantity, volume } = parseProductSpecificName(inputValue);
                        setFieldValue('quantity', quantity);
                        setFieldValue('volume', volume);
                      }}
                    />
                  </div>
                  <ErrorMessage name="productSpecificName" component="p" className="text-danger" />
                  <div className="d-flex flex-column">
                    <label className="form-label mt-2">Discontinued?</label>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          style={{ width: 40, height: 20, cursor: 'pointer' }}
                          role="switch"
                          id="isDiscontinued"
                          checked={selectedObject?.isDiscontinued ? true : false}
                          onClick={() =>
                            setSelectedObject({
                              ...selectedObject,
                              isDiscontinued: selectedObject?.isDiscontinued ? false : true,
                            })
                          }
                        />
                      </div>
                      <OverlayTrigger
                        placement={'right'}
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            A discontinued product is one that is STILL seen in a forecast as long as there were actual
                            sales in the period previous to a forecast. Its revenue IS accounted for as long as there
                            are sales. If there are no sales, the product will no longer show in future forecasts.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon style={{ fontSize: '15px' }} className="mx-3" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </div>
                    <label className="form-label mt-2">Active?</label>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          style={{ width: 40, height: 20, cursor: 'pointer' }}
                          role="switch"
                          id="isActive"
                          checked={selectedObject?.productIsActive}
                          onClick={() =>
                            setSelectedObject({ ...selectedObject, productIsActive: !selectedObject.productIsActive })
                          }
                        />
                      </div>
                      <OverlayTrigger
                        placement={'right'}
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            An inactive product is NOT seen in any new forecasts regardless of previous sales. Its
                            revenue is NOT accounted for.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon style={{ fontSize: '15px' }} className="mx-3" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="col ps-4 col-3" style={{ borderRight: '1px solid #EEEEEE' }}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Label</label>
                      <div>
                        <Select
                          isSearchable={true}
                          defaultInputValue=""
                          value={selectedOptionLabel}
                          className="w-100"
                          onChange={(option: any) => {
                            setFieldValue('labelId', option.value);
                            setFieldValue(
                              'prevProductName',
                              `${option.brandFamily.brand.name as string} ${option.labelName as string}`,
                            );
                          }}
                          options={labels}
                          styles={{
                            container: (baseStyles) => ({
                              ...baseStyles,
                              width: '180px',
                            }),
                            control: (baseStyles) => ({
                              ...baseStyles,
                              minHeight: '27px',
                              borderColor: 'rgb(118, 118, 118)',
                            }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '27px',
                              alignItems: 'normal',
                            }),
                            indicatorsContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '27px',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="form-label">National Price</label>
                      <div>
                        <Field name="nationalPrice" type="text" className="w-100" />
                        <ErrorMessage name="nationalPrice" component="p" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="form-label">FET</label>
                      <div>
                        <Field name="federalExciseTax" type="text" className="w-100" />
                        <ErrorMessage name="federalExciseTax" component="p" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="form-label">Quantity</label>
                      <div>
                        <Field name="quantity" type="text" className="w-100" />
                        <ErrorMessage name="quantity" component="p" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="form-label">Volume</label>
                      <div>
                        <Field name="volume" type="text" className="w-100" />
                        <ErrorMessage name="volume" component="p" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="form-label">Park Street Product Key</label>
                      <div>
                        <Field name="bbpProductNumber" type="text" className="w-100" />
                        <ErrorMessage name="bbpProductNumber" component="p" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col ps-4" style={{ borderRight: '1px solid #EEEEEE' }}>
                  <label className="form-label">Pricing</label>
                  <div>
                    <div className="d-flex">
                      <div>
                        <Select
                          isSearchable={true}
                          defaultInputValue=""
                          onChange={(option: any) => {
                            const territoryExist = territoriesPrices.some(
                              (item: any) => item.territoryId === option.territoryId,
                            );
                            if (!territoryExist) {
                              setTerritoriesPrices([...territoriesPrices, { ...option, price: 0 }]);
                              if (scrollableRef && scrollableRef.current) {
                                scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
                              }
                            }
                          }}
                          options={territories}
                          styles={{
                            container: (baseStyles) => ({
                              ...baseStyles,
                              width: '180px',
                            }),
                            control: (baseStyles) => ({
                              ...baseStyles,
                              minHeight: '27px',
                              borderColor: 'rgb(118, 118, 118)',
                            }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '27px',
                              alignItems: 'normal',
                            }),
                            indicatorsContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '27px',
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="table-responsive table-sm"
                      ref={scrollableRef}
                      style={{ fontSize: '11px', maxHeight: '450px' }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="font-weight-light text-secondary">State</th>
                            <th className="text-center font-weight-light text-secondary">Price</th>
                            <th className="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {territoriesPrices?.length === 0 ? (
                            <p>No new State Prices. Add one above</p>
                          ) : (
                            territoriesPrices.map((terr: any) => (
                              <tr key={terr.territoryId}>
                                <td>
                                  {terr.name} ({terr.shortName})
                                </td>
                                <td className="text-center">
                                  <input
                                    type="text"
                                    value={terr.price}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === '-' || inputValue === '') {
                                        handlePriceChange(terr.territoryId, inputValue);
                                      } else {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {Number(terr.price) <= 0 && (
                                    <p className="text-danger">Price must be greater than 0-</p>
                                  )}
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-danger btn-sm"
                                    type="button"
                                    onClick={() => {
                                      const territoryExist = deletedTerritoryPrices.some(
                                        (item: any) => item.territoryId === terr.territoryId,
                                      );
                                      if (!territoryExist) {
                                        setDeletedTerritoryPrices((prevItems: any) => [...prevItems, terr]);
                                      }
                                      setTerritoriesPrices((prevItems: any) =>
                                        prevItems.filter((item: any) => item.territoryId !== terr.territoryId),
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '13px' }} />
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-danger col d-flex justify-content-end">{errorMessage}</p>
                <div className="col d-flex justify-content-end">
                  <button className="btn btn-primary float-end" type="submit" style={{ textAlign: 'right' }}>
                    {isEdit ? 'Save' : 'Create'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProductForm;
