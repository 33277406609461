import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

export default function TableHead(props: any) {
  const { headerGroups } = props;
  return (
    <thead>
      {headerGroups.map((headerGroup: any, index: number) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header">
          {headerGroup.headers.map((column: any, index: number) => {
            const sortProps = column.canSort
              ? column.getHeaderProps(column.getSortByToggleProps())
              : column.getHeaderProps();
            return (
              <th {...sortProps} key={index} className="table-head-font-size">
                {column.render('Header')}
                <span>
                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                    )
                  ) : null}
                </span>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
