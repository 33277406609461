import React, { ReactNode, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useExpanded, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

interface ICustomTable {
  scope?: any;
  columns: any;
  row?: number;
  data: any;
  search?: string;
  footer?: ReactNode;
  onRowClick?: (x: number) => void;
  getClass?: (x: any, y: number) => void;
}

const CustomTable: React.FC<ICustomTable> = ({ scope, columns, data, row, footer, onRowClick, search }) => {
  const [rowSelect, setRowSelect] = useState(row);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    setGlobalFilter(search);
  }, [search]);

  useEffect(() => {
    setRowSelect(row);
  }, [row]);

  return (
    <div>
      <Table striped bordered {...getTableProps()} className="table-sm">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-gray">
              {headerGroup.headers.map((column, index) => {
                const sortProps = column.canSort
                  ? column.getHeaderProps(column.getSortByToggleProps())
                  : column.getHeaderProps();
                return (
                  <th {...sortProps} key={index} className="table-head-font-size">
                    {column.render('Header')}
                    <span>
                      {column.canSort ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                          ) : (
                            <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )
                      ) : null}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr
                  {...row.getRowProps()}
                  className={`table-body-font-size ${rowSelect && row.id === rowSelect ? 'table-info' : ''}`}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        style={{
                          border: 'none',
                          backgroundColor: !scope && scope === 'Management' && '#fff',
                        }}
                        className={'text-dark align-middle'}
                        onClick={() => onRowClick}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>{footer}</tfoot>
      </Table>
    </div>
  );
};

export default CustomTable;
