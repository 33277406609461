import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { Table } from 'react-bootstrap';
import ForecastService from '../../services/forecast.service';
import { useForecast } from '../../providers/ForecastProvider';
import { useProjection } from '../../providers/ProjectionProvider';
import { calculateForecastQuantity, calculateForecastRevenue, calculateRevenueGrowth } from '../../utils/calculation';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { hideLoading, showLoading } from '../../lib/uiService';
import { ConfigService } from '../../services/config.service';
import { validateOnChangeInput } from '../../utils/validation';
import StageButton from '../../components/projection/StageButton';
import DownloadSelector from '../../components/DownloadSelector';
import TableHead from '../../components/common/TableHead';
import useErrorHandling from '../../hooks/useErrorHandling';
import TableBody from '../../components/common/TableBody';
import { useBrandForecast } from '../../providers/BrandForecastProvider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../providers/AuthProvider';
import Pagination from '../../components/Pagination';

export interface IBrandForecast {
  forecastBudgetId: string;
  brandName: string;
  brandId: string;
  priorQuantity: number;
  forecastQuantity: number;
  salesGrowth: number;
  priceGrowth: number;
  priorRevenue: number;
  forecastRevenue: number;
  revenueGrowth: number;
  execQuantity: number;
  execRevenue: number;
  salesDiffPercent?: number;
  revenueDiffPercent?: number;
}

interface IForecastBudget {
  forecastBudgetId: string;
  name: string;
  brandId: string;
  priorYearSales: number;
  quantity: number;
  salesGrowth: number;
  priceGrowth: number;
  priorYearRevenue: number;
  revenue: number;
  execQuantity: number;
  execRevenue: number;
  explanation?: string;
}

interface IBrandForecastTable {
  scope: 'Management' | 'Regional';
  // had to define row prop-type to render cells in each column
  row: any;
  setForecastBudgetData?: any;
}

const config = ConfigService.GetConfig();
const mediumGrowthMin = config.growthMin;
const mediumGrowthMax = config.growthMax;
const lowBgColor = config.lowGrowthColor;
const mediumBgColor = config.mediumGrowthColor;
const highBgColor = config.highGrowthColor;

const BrandForecastTable: React.FC<IBrandForecastTable> = ({ scope, setForecastBudgetData }) => {
  const ref = React.useRef<any>({});
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const { forecastId, territoryId, hasForecasts, forecastCalendarId, isForecastFromParams, currentForecastYear } =
    useForecast();
  const { role } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [startPage, setStartPage] = useState<number>(1);
  const [currentStartPage, setCurrentStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [search] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [brandForecasts, setBrandForecasts] = useState<IBrandForecast[]>([]);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<IBrandForecast[]>([]);
  const projectionContext = useProjection();
  const { regionalData, setRegionalData } = useBrandForecast();
  const [isFetching, $isFetching] = useState<boolean>(false);

  const isExecTable = scope === 'Management';

  Object.assign(ref.current, { brandForecasts, initialData, regionalData });

  useEffect(() => {
    // make sure forecastCalendarId exist before loading
    const localStorageCalendarId = localStorage.getItem('timePeriod') || '';
    const calendarId = forecastCalendarId ? forecastCalendarId : localStorageCalendarId;
    if (hasForecasts && forecastId && calendarId && territoryId) {
      $isFetching(true);
      ForecastService.GetForecastBudget({
        page,
        pageSize,
        forecastId: forecastId,
        search,
        forecastCalendarId: calendarId,
        territoryId: territoryId,
        isExec: isExecTable,
      })
        .then(async ({ data }) => {
          if (!isExecTable) {
            await projectionContext.handlers.fetch(forecastId);
            const projection = projectionContext.getProjection();
            if (projection) {
              projection.applyToForecastData(data?.forecastBudgets);
            }
          }
          const dataToInsert = data.forecastBudgets?.map((forecastBudget: IForecastBudget) => {
            const {
              forecastBudgetId,
              name,
              brandId,
              priorYearSales,
              quantity,
              salesGrowth,
              priceGrowth,
              priorYearRevenue,
              revenue,
              execQuantity,
              execRevenue,
              explanation,
            } = forecastBudget;
            return {
              forecastBudgetId,
              brandName: name,
              brandId,
              priorQuantity: priorYearSales,
              forecastQuantity: quantity,
              salesGrowth,
              priceGrowth,
              priorRevenue: priorYearRevenue,
              forecastRevenue: revenue,
              revenueGrowth: calculateRevenueGrowth(salesGrowth, priceGrowth),
              execQuantity,
              execRevenue,
              explanation,
              salesDiffPercent: (quantity / execQuantity) * 100 - 100,
              revenueDiffPercent: (revenue / execRevenue) * 100 - 100,
            };
          });
          if (scope === 'Regional') setRegionalData(dataToInsert);
          setBrandForecasts(dataToInsert);
          setInitialData(dataToInsert);
          setCount(data.count);
          setIsEditable(data.isEditable);
          setTotalPages(Math.ceil(data.count / pageSize));
        })
        .catch(({ response }) => {
          console.log(response);
          setError({ status: response.status || 500 });
        })
        .finally(() => {
          $isFetching(false);
        });
    }
  }, [page, pageSize, forecastId, search, territoryId, forecastCalendarId]);

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  const updateBrandData = (prevData: any, id: string, updatedData: any) => {
    return prevData.map((row: any) => {
      if (row.forecastBudgetId === id) {
        const newRow = {
          ...row,
          ...updatedData,
        };
        const newForecastRevenue = calculateForecastRevenue(
          row['forecastRevenue'],
          row['salesGrowth'],
          row['priceGrowth'],
          newRow['salesGrowth'],
          newRow['priceGrowth'],
        );
        const newRevDiff = row.execRevenue ? (newForecastRevenue / row.execRevenue) * 100 - 100 : 0;
        const newRevenueGrowth = calculateRevenueGrowth(newRow['salesGrowth'], newRow['priceGrowth']);
        if (Object.keys(updatedData).includes('salesGrowth')) {
          const newForecastQuantity = calculateForecastQuantity(
            row['forecastQuantity'],
            row['salesGrowth'],
            newRow['salesGrowth'],
          );
          if (scope === 'Regional') {
            setForecastBudgetData((prevData: any) => {
              return {
                ...prevData,
                cases: Number(prevData.cases) - row['forecastQuantity'] + newForecastQuantity,
                revenue: Number(prevData.revenue) - row['forecastRevenue'] + newForecastRevenue,
              };
            });
          }
          const newSalesDiff = row.execQuantity ? (newForecastQuantity / row.execQuantity) * 100 - 100 : 0;
          return {
            ...newRow,
            ...(scope === 'Management' && {
              execQuantity: newForecastQuantity,
              execRevenue: newForecastRevenue,
            }),
            forecastQuantity: newForecastQuantity,
            forecastRevenue: newForecastRevenue,
            revenueGrowth: newRevenueGrowth,
            salesDiffPercent: newSalesDiff,
            revenueDiffPercent: newRevDiff,
          };
        } else {
          if (scope === 'Regional') {
            setForecastBudgetData((prevData: any) => {
              return {
                ...prevData,
                revenue: Number(prevData.revenue) - row['forecastRevenue'] + newForecastRevenue,
              };
            });
          }
          return {
            ...newRow,
            ...(scope === 'Management' && {
              execRevenue: newForecastRevenue,
            }),
            forecastRevenue: newForecastRevenue,
            revenueGrowth: newRevenueGrowth,
            revenueDiffPercent: newRevDiff,
          };
        }
      } else {
        return row;
      }
    });
  };

  const handleChange = (id: string, updatedData: any) => {
    if (scope === 'Regional') {
      setRegionalData((prevData: any) => {
        return updateBrandData(prevData, id, updatedData);
      });
    } else {
      setBrandForecasts((prevData) => {
        const updatedManagementData = updateBrandData(prevData, id, updatedData);
        const rowFromManagement = updatedManagementData.find((item: any) => id === item.forecastBudgetId);
        setRegionalData((prevData: any) => {
          return prevData.map((row: any) => {
            if (row.brandId === rowFromManagement.brandId) {
              const newRevDiff =
                rowFromManagement.execRevenue !== 0
                  ? (row.forecastRevenue / rowFromManagement.execRevenue) * 100 - 100
                  : 0;
              if (Object.keys(updatedData).includes('salesGrowth')) {
                const newSalesDiff =
                  rowFromManagement.forecastQuantity !== 0
                    ? (row.forecastQuantity / rowFromManagement.forecastQuantity) * 100 - 100
                    : 0;

                return {
                  ...row,
                  execQuantity: rowFromManagement.forecastQuantity,
                  execRevenue: rowFromManagement.execRevenue,
                  salesDiffPercent: newSalesDiff,
                  revenueDiffPercent: newRevDiff,
                };
              } else {
                return {
                  ...row,
                  execRevenue: rowFromManagement.execRevenue,
                  revenueDiffPercent: newRevDiff,
                };
              }
            } else {
              return row;
            }
          });
        });
        return updatedManagementData;
      });
    }
  };

  const getChangedRows = React.useCallback(() => {
    const changedRows =
      scope === 'Management'
        ? ref.current.brandForecasts.filter((brandForecast: any, index: number) => {
            return (
              brandForecast?.salesGrowth !== initialData[index].salesGrowth ||
              brandForecast?.priceGrowth !== initialData[index].priceGrowth
            );
          })
        : ref.current.regionalData.filter((brandForecast: any, index: number) => {
            return (
              brandForecast?.salesGrowth !== initialData[index].salesGrowth ||
              brandForecast?.priceGrowth !== initialData[index].priceGrowth
            );
          });
    return changedRows;
  }, [ref.current.brandForecasts, isFetching]);
  const handleSave = (changedData?: any) => {
    const changedRows = changedData ? changedData : getChangedRows();
    if (changedRows.length === 0) return;
    const data = changedRows.map(({ forecastBudgetId, salesGrowth, priceGrowth, explanation }: any) => ({
      forecastBudgetId,
      salesGrowth,
      priceGrowth,
      explanation,
    }));

    showLoading();
    if (isExecTable) {
      return ForecastService.UpdateForecastBudget({
        territoryId,
        forecastId,
        isExec: isExecTable,
        data,
      })
        .then(() => {
          hideLoading();
          setInitialData(scope === 'Management' ? brandForecasts : regionalData);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } else {
      return projectionContext.handlers
        .update({
          territoryId,
          forecastId,
          isExec: false,
          data,
        })
        .then(() => {
          hideLoading();
          toast.success('Change Successfully Staged');
          setInitialData(scope === 'Regional' ? regionalData : brandForecasts);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  };

  const showProductForecast = (brandId: string, brandName: string) => {
    navigate(
      isForecastFromParams
        ? `/forecast/product/${brandId}?forecastId=${forecastId}&forecastCalendarId=${forecastCalendarId}&territoryId=${territoryId}&year=${currentForecastYear}`
        : `/forecast/product/${brandId}`,
      {
        state: {
          brandName,
        },
      },
    );
  };

  const totalPriorYearSales = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.priorQuantity), 0);
  }, [initialData]);

  const totalBaseForecastSales = useMemo(() => {
    return initialData.reduce(
      (total, item) => total + (Number(item.forecastQuantity) * 100) / (100 + Number(item.salesGrowth)),
      0,
    );
  }, [initialData]);

  const totalForecastSales = useMemo(() => {
    return scope === 'Management'
      ? brandForecasts.reduce((total, item) => total + Number(item.forecastQuantity), 0)
      : regionalData.reduce((total, item) => total + Number(item.forecastQuantity), 0);
  }, [brandForecasts, regionalData]);

  const totalPriorYearRevenue = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.priorRevenue), 0);
  }, [initialData]);

  const totalBaseForecastRevenue = useMemo(() => {
    return initialData.reduce(
      (total, item) =>
        total + (Number(item.forecastRevenue) * 100) / (100 + formatNegativeNumFromStr(item.revenueGrowth)),
      0,
    );
  }, [initialData]);

  const totalForecastRevenue = useMemo(() => {
    return scope === 'Management'
      ? brandForecasts.reduce((total, item) => total + formatNegativeNumFromStr(item.forecastRevenue), 0)
      : regionalData.reduce((total, item) => total + formatNegativeNumFromStr(item.forecastRevenue), 0);
  }, [brandForecasts, regionalData]);

  const totalPriceGrowth = useMemo(() => {
    const dataToTest = scope === 'Management' ? brandForecasts : regionalData;
    return dataToTest.length > 0
      ? dataToTest.reduce((total, item) => total + formatNegativeNumFromStr(item.priceGrowth), 0) / dataToTest.length
      : 0;
  }, [brandForecasts, regionalData]);

  const tableItemStyle = (
    scope === 'Regional'
      ? {
          backgroundColor: 'unset',
          '--bs-table-accent-bg': 'unset',
          color: 'rgb(41 48 66)',
        }
      : {}
  ) as React.CSSProperties;

  function getClass(scope: any, diffPercent: number) {
    return scope === 'Regional'
      ? `${
          (diffPercent || 0) < Number(mediumGrowthMin)
            ? lowBgColor
            : (diffPercent || 0) > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark align-middle`
      : '';
  }

  const totalSalesGrowthPercentage = totalPriorYearSales
    ? (totalForecastSales / totalBaseForecastSales - 1) * 100.0
    : 0;

  const totalSalesGrowthClass =
    scope === 'Regional'
      ? `${
          totalSalesGrowthPercentage < Number(mediumGrowthMin)
            ? lowBgColor
            : totalSalesGrowthPercentage > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark`
      : '';

  const totalRevenueGrowthPercentage = totalPriorYearRevenue
    ? (totalForecastRevenue / totalBaseForecastRevenue - 1) * 100.0
    : 0;

  const totalRevenueGrowthClass =
    scope === 'Regional'
      ? `${
          totalRevenueGrowthPercentage < Number(mediumGrowthMin)
            ? lowBgColor
            : totalRevenueGrowthPercentage > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark`
      : '';

  // values set to any (to not cause errors) because libary's types are not compatible
  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'brandName',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return scope === 'Management' ? (
            forecastData.brandName
          ) : (
            <a
              style={{ textDecoration: 'underline' }}
              className="link-opacity-50-hover text-dark"
              onClick={() => showProductForecast(forecastData.brandId, forecastData.brandName)}
            >
              {forecastData.brandName}
            </a>
          );
        },
      },
      {
        accessor: 'priorQuantity',
        Header: 'Prior Year Sales (cases)',
        canSort: true,
        Cell: ({ value }: { value: any }) => {
          return `${formatNum(value, 1, 1)}`;
        },
      },
      {
        Header: 'Current Forecast',
        accessor: 'forecastQuantity',
        canSort: true,
        Cell: ({ value }: { value: any }) => {
          return `${formatNum(value, 1, 1)}`;
        },
      },
      {
        Header: scope === 'Management' ? 'Mgmt Case Growth' : 'RM Case Growth',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle}>
              {isEditable ? (
                <input
                  type="text"
                  style={{ width: '45px' }}
                  value={forecastData.salesGrowth}
                  onChange={(e) => {
                    validateOnChangeInput(e, forecastData.forecastBudgetId, 'salesGrowth', handleChange);
                  }}
                />
              ) : (
                <>{formatNum(forecastData.salesGrowth)}</>
              )}
              &nbsp;%
            </td>
          );
        },
      },
      {
        Header: scope === 'Management' ? 'Mgmt Price Growth' : 'RM Price Growth',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle}>
              {isEditable ? (
                <input
                  type="text"
                  style={{ width: '45px' }}
                  value={forecastData.priceGrowth}
                  onChange={(e) => {
                    validateOnChangeInput(e, forecastData.forecastBudgetId, 'priceGrowth', handleChange);
                  }}
                />
              ) : (
                <>{formatNum(forecastData.priceGrowth)}</>
              )}
              &nbsp;%
            </td>
          );
        },
      },
      {
        Header: 'Prior Year Revenue',
        accessor: 'priorRevenue',
        Cell: ({ value }: { value: any }) => {
          return `$${formatNum(value)}`;
        },
        canSort: true,
      },
      {
        Header: 'Forecasted Revenue',
        accessor: 'acc',
        canSort: true,
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.forecastRevenue;
          const valueB = rowB.original.forecastRevenue;
          return valueA - valueB;
        },
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle} className={getClass(scope, Number(forecastData.revenueDiffPercent))}>
              ${formatNum(forecastData.forecastRevenue)}
            </td>
          );
        },
      },
      {
        id: 'save',
        Header: 'Mgmt Revenue Growth',
        canSort: true,
        accessor: 'acc2',
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];

          return (
            <td style={tableItemStyle} className="cursor-pointer">
              {formatNum(forecastData.revenueGrowth) === '-0.00'
                ? formatNum(0)
                : formatNum(forecastData.revenueGrowth, 1, 1)}
              %
            </td>
          );
        },
      },
      isEditable &&
        !isExecTable && {
          id: 'action',
          Header: '',
          disableSortBy: true,
          accessor: 'action',
          Cell: ({ row }: { row: any }) => {
            const changedRows = getChangedRows();
            const changedItem = changedRows.find(
              (item: any) => item.forecastBudgetId === row.original.forecastBudgetId,
            );
            if (!changedItem && !row.original.explanation) return null;
            return (
              <td style={tableItemStyle} className="cursor-pointer">
                <StageButton
                  changedItem={row.original}
                  handleSave={() => {
                    handleSave([row.original]);
                  }}
                />
              </td>
            );
          },
        },
    ].filter(Boolean);
  }, [isEditable, isFetching]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns: columns,
      data: useMemo(() => {
        if (scope === 'Regional') {
          return regionalData;
        } else {
          return brandForecasts;
        }
      }, [brandForecasts, regionalData, scope, isFetching]),
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  const downloadColumnHeaders = [
    'Name',
    'Prior Year Sales (cases)',
    'Current Forecast',
    scope === 'Management' ? 'Mgmt Case Growth' : 'RM Case Growth',
    scope === 'Management' ? 'Mgmt Price Growth' : 'RM Price Growth',
    'Prior Year Revenue',
    'Forecasted Revenue',
    'Mgmt Revenue Growth',
  ];

  const row: any = [];
  const blurredStyle = {
    filter: isFetching ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };
  return (
    <div className="card shadow" style={{ marginBottom: '44px' }}>
      {isFetching && (
        <div
          className="position-absolute d-flex align-items-center justify-content-center"
          style={{ height: '900px', width: '960px' }}
        >
          <div
            className="me-2 spinner-border text-info"
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          ></div>
        </div>
      )}
      <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">
          {scope === 'Management' ? 'Management Forecast Breakdown' : 'Regional Manager Forecast Breakdown'}
        </p>
      </div>
      <div className="card-body" style={blurredStyle}>
        <div className="row">
          <div className="col-md-6 text-nowrap">
            <div className="dataTables_length" aria-controls="dataTable">
              <label className="form-label">
                Show&nbsp;
                <select
                  value={pageSize}
                  className="d-inline-block form-select form-select-sm"
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    setPage(1);
                    setCurrentStartPage(1);
                  }}
                >
                  <option value={Number.MAX_SAFE_INTEGER}>All</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                &nbsp;
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
              <label className="form-label d-flex justify-content-md-end">
                <DownloadSelector
                  row={row}
                  hasFullForecast={role === 'Administrator'}
                  fileName={`${scope} Forecast Breakdown`}
                  downloadColumnHeaders={downloadColumnHeaders}
                  prepareDataFunction={() => {
                    const dataToUse = scope === 'Management' ? brandForecasts : regionalData;
                    dataToUse.forEach((item) => {
                      row.push([
                        { v: item.brandName, t: 's' },
                        { v: formatNumOneDecimal(item.priorQuantity), t: 's' },
                        { v: formatNumOneDecimal(item.forecastQuantity), t: 's' },
                        { v: `${formatNumOneDecimal(item.salesGrowth)}%`, t: 's' },
                        { v: `${formatNumOneDecimal(item.priceGrowth)}%`, t: 's' },
                        { v: `$${formatNum(item.priorRevenue)}`, t: 's' },
                        { v: `${formatNumOneDecimal(item.forecastRevenue)}`, t: 's' },
                        { v: `$${formatNum(item.revenueGrowth)}`, t: 's' },
                      ]);
                    });
                  }}
                />
                &nbsp;
              </label>
              <label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Search"
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={scope !== 'Management' ? 'dont-alternate-row-color' : ''}>
          <Table striped className="table-sm" bordered {...getTableProps()}>
            <TableHead headerGroups={headerGroups} />
            <TableBody
              {...getTableBodyProps()}
              rows={rows}
              renderRow={(row: any, key: number) => {
                prepareRow(row);
                const forecastData = row.original;
                return (
                  <React.Fragment key={key}>
                    <tr
                      {...row.getRowProps()}
                      key={key}
                      className={`table-body-font-size ${getClass(scope, Number(forecastData.salesDiffPercent))}`}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={`${key}-${index}`}
                            style={{
                              border: 'none',
                              backgroundColor: scope === 'Management' && '#fff',
                            }}
                            className={'text-dark align-middle'}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              }}
            />
            <tfoot>
              <tr>
                <td>
                  <strong>Grand Total</strong>
                </td>
                <td>
                  <strong>{formatNum(totalPriorYearSales, 1, 1)}</strong>
                </td>
                <td className={`${totalSalesGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalForecastSales, 1, 1)}
                  </strong>
                </td>
                <td className={`${totalSalesGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalSalesGrowthPercentage, 1, 1)}%
                  </strong>
                </td>
                <td>
                  <strong>
                    {formatNum(totalPriceGrowth) === '-0.00' ? formatNum(0) : formatNum(totalPriceGrowth, 1, 1)}%
                  </strong>
                </td>
                <td>
                  <strong>${formatNum(totalPriorYearRevenue)}</strong>
                </td>
                <td className={`${totalRevenueGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    ${formatNum(totalForecastRevenue)}&nbsp;
                  </strong>
                </td>
                <td className={`${totalRevenueGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalRevenueGrowthPercentage, 1, 1)}%
                  </strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
              Showing {pageSize > count ? count : pageSize} of {count}
            </p>
          </div>
          <div className="col-md-6 mb-2">
            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  >
                    <span aria-hidden="true">«</span>
                  </div>
                </li>
                <Pagination
                  currentStartPage={currentStartPage}
                  totalPages={totalPages}
                  startPage={startPage}
                  pageSize={pageSize}
                  count={count}
                  page={page}
                  setPage={setPage}
                  setData={setBrandForecasts}
                  initialData={initialData}
                  setCurrentStartPage={setCurrentStartPage}
                />
                <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Next"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    <span aria-hidden="true">»</span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {isEditable && isExecTable && (
          <div className="mb-2">
            <button className="btn btn-primary float-end" type="button" onClick={() => handleSave()}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandForecastTable;
