import React, { type PropsWithChildren, useState } from 'react';

import { createSafeContext } from './createSafeContext';

interface IAuthContext {
  isLoggedIn: boolean;
  setIsLoggedIn: (x: boolean) => void;
  isCheckingLoggedState: boolean;
  setisCheckingLoggedState: (x: boolean) => void;
  role: string;
  userLogo: string;
  setUserLogo: (x: string) => void;
  setRole: (x: string) => void;
  userName: string;
  setUserName: (x: string) => void;
  isAdmin: () => boolean;
}

const [useAuth, Provider] = createSafeContext<IAuthContext>();

export { useAuth };

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isCheckingLoggedState, setisCheckingLoggedState] = useState<boolean>(true);
  const [role, setRole] = useState<string>('');
  const [userLogo, setUserLogo] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const isAdmin = React.useCallback(() => role === 'Administrator', [role]);

  return (
    <Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        role,
        setRole,
        userLogo,
        setUserLogo,
        userName,
        setUserName,
        isCheckingLoggedState,
        setisCheckingLoggedState,
        isAdmin,
      }}
    >
      {children}
    </Provider>
  );
};
