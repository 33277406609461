import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { Table } from 'react-bootstrap';

import { hideLoading, showLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import DownloadSelector from '../../components/DownloadSelector';
import useForecastModal from '../../hooks/useForecastModal';
import useErrorHandling from '../../hooks/useErrorHandling';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import { useAuth } from '../../providers/AuthProvider';
import Pagination from '../../components/Pagination';

interface YearData {
  totalQuantity: string | null;
  totalRevenue: string | null;
}

interface IProductForecast {
  forecastProductId: string;
  description: string;
  productId: string;
  year2: YearData;
  execQuantity: number;
  execSalesGrowth: number;
  execRevenue: number;
  execPriceGrowth: number;
  salesGrowth: number;
  priceGrowth: number;
  isOverridden: boolean;
  revenue: number;
  price: number;
  quantity: number;
  salesDiffPercent?: number;
  revenueDiffPercent?: number;
}

interface IProduct {
  description: string;
  federalExciseTax: string;
  forecastProducts: IProductForecast[];
  labelId: string;
  productId: string;
  quantity: string | null;
  volume: string | null;
  year2: number | undefined;
  execQuantity: number | undefined;
  execSalesGrowth: number | undefined;
  execRevenue: number | undefined;
  price: number | undefined;
}

const TopProducts: React.FC = () => {
  const { forecastId, territoryId, hasForecasts, forecastCalendarId, currentForecastYear } = useForecast();
  const { setError } = useErrorHandling();
  const { isLoggedIn, isCheckingLoggedState } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [startPage, setStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isDataFromSales, setIsDataFromSales] = useState<boolean>(false);
  const [isPartialYear, setIsPartialYear] = useState<boolean>(false);
  const [productForecasts, setProductForecasts] = useState<IProduct[]>([]);
  const [count, setCount] = useState<number>(0);
  const [initialData, setInitialData] = useState<IProduct[]>([]);
  const { showForecastModal, setShowForecastModal } = useForecastModal();

  useEffect(() => {
    if (
      hasForecasts &&
      forecastId &&
      territoryId &&
      forecastCalendarId &&
      currentForecastYear &&
      isLoggedIn &&
      !isCheckingLoggedState
    ) {
      fetchForecastProduct();
    }
  }, [forecastId, territoryId, forecastCalendarId, currentForecastYear, isLoggedIn, isCheckingLoggedState]);

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  const fetchForecastProduct = async () => {
    showLoading();
    try {
      // make sure forecastCalendarId exist before loading
      const localStorageCalendarId = localStorage.getItem('timePeriod') || '';
      const calendarId = forecastCalendarId ? forecastCalendarId : localStorageCalendarId;
      const { data } = await ForecastService.GetForecastBestProducts({
        page,
        forecastId,
        search: '',
        territoryId,
        forecastCalendarId: calendarId,
        currentForecastYear,
      });
      setIsDataFromSales(data.isDataFromSales);
      setIsPartialYear(data.isPartialYear);
      calculateDiffs(data.forecastProducts || []);
      setCount(data.count);
      setTotalPages(Math.ceil(data.count / pageSize));
    } catch (error: any) {
      console.log(error);
      setError({ status: error.response.status || 500 });
    } finally {
      hideLoading();
    }
  };

  const calculateDiffs = (data: IProduct[]) => {
    const dataToInsert = data;
    setProductForecasts(dataToInsert.slice(0, pageSize));
    setInitialData(dataToInsert);
  };

  const calculateRateChange = (dividend: number | string, divisor: number | string) => {
    const ratePercentage =
      divisor && divisor !== '0' && divisor !== 0
        ? Number(divisor) !== 0
          ? ((Number(dividend) - Number(divisor)) / Number(divisor)) * 100
          : 0
        : 0;
    // prevent -0 and -0.000433 cases: fix to one decimal and pass to absolute if equal to 0
    const fixedRatePercentage = Number(ratePercentage.toFixed(1));
    const formattedGrowthPercentage = Math.abs(fixedRatePercentage) === 0 ? 0 : ratePercentage;
    return formattedGrowthPercentage;
  };

  // values set to any (to not cause errors) because libary's types are not compatible
  const columns: any = useMemo(() => {
    return [
      {
        id: 'Product',
        Header: 'Product',
        accessor: 'description',
        width: 2,
      },
      {
        id: 'Product2',
        accessor: 'quantity',
        Header: `${isDataFromSales && isPartialYear ? 'Estimated cases for' : isDataFromSales && !isPartialYear ? 'Shipments for' : 'Forecasted cases for'} ${Number(currentForecastYear) - 1}`,
        width: 1,
        sortType: (rowA: any, rowB: any) => {
          const valueA = Number(rowA.original.year2.totalQuantity || 0);
          const valueB = Number(rowB.original.year2.totalQuantity || 0);
          return valueA - valueB;
        },
        Cell: ({ data, row }: { data: any; row: any }): any => {
          const forecastData = data[row.id];
          return `${formatNumOneDecimal(Number(forecastData.year2.totalQuantity))}`;
        },
      },
      {
        id: 'Product3',
        Header: `Forecasted Cases ${Number(currentForecastYear)}`,
        accessor: 'quantity',
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return `${formatNumOneDecimal(forecastData.quantity)}`;
        },
        width: 1,
      },
      {
        id: 'Product4',
        Header: `Forecasted Case Growth`,
        accesor: 'quantity',
        canSort: true,
        width: 1,
        sortType: (rowA: any, rowB: any) => {
          const valueA = calculateRateChange(Math.floor(rowA.original.quantity), rowA.original.year2.totalQuantity);
          const valueB = calculateRateChange(Math.floor(rowB.original.quantity), rowB.original.year2.totalQuantity);
          return valueA - valueB;
        },
        Cell: ({ data, row }: { data: any; row: any }): any => {
          const forecastData = data[row.id];
          const value = calculateRateChange(Math.floor(forecastData.quantity), forecastData.year2.totalQuantity);
          return `${formatNumOneDecimal(value)}%`;
        },
      },
      {
        id: 'Product5',
        Header: `${isDataFromSales && isPartialYear ? 'Estimated revenue for ' : isDataFromSales && !isPartialYear ? 'Revenue for ' : 'Forecasted revenue for'} ${Number(currentForecastYear) - 1}`,
        accesor: 'quantity',
        canSort: true,
        sortType: (rowA: any, rowB: any) => {
          const valueA = Number(rowA.original.year2.totalRevenue || 0);
          const valueB = Number(rowB.original.year2.totalRevenue || 0);
          return valueA - valueB;
        },
        width: 1,
        Cell: ({ data, row }: { data: any; row: any }): any => {
          const forecastData = data[row.id];
          return `$${formatNum(Number(forecastData.year2.totalRevenue))}`;
        },
      },
      {
        id: 'Product6',
        Header: `Forecasted Revenue ${Number(currentForecastYear)}`,
        accessor: 'revenue',
        width: 1,
        Cell: ({ value }: { value: any }) => {
          return `$${formatNum(value)}`;
        },
      },
      {
        id: 'Product7',
        Header: `Revenue Growth`,
        accessor: 'revenue',
        width: 1,
        sortType: (rowA: any, rowB: any) => {
          const valueA = calculateRateChange(rowA.original.revenue, rowA.original.year2.totalRevenue);
          const valueB = calculateRateChange(rowB.original.revenue, rowB.original.year2.totalRevenue);
          return valueA - valueB;
        },
        Cell: ({ data, row }: { data: any; row: any }): any => {
          const forecastData = data[row.id];
          const value = calculateRateChange(forecastData.revenue, forecastData.year2.totalRevenue);

          return `${formatNumOneDecimal(value)}%`;
        },
      },
    ];
  }, [productForecasts]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns: columns,
      data: useMemo(() => productForecasts, [productForecasts]),
    },
    useGlobalFilter,
    useSortBy,
  );

  const downloadColumnHeaders = [
    'Product',
    `Estimated Cases sold ${Number(currentForecastYear) - 1}`,
    `Forecasted Cases ${Number(currentForecastYear)}`,
    'Forecasted Case Growth',
    `Estimated Revenue ${Number(currentForecastYear) - 1}`,
    `Forecasted Revenue ${Number(currentForecastYear)}`,
    'Revenue Growth',
  ];

  const row: any = [];

  return hasForecasts ? (
    <>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <h3 className="text-dark mb-4">Top 100 Products</h3>
      <div className="card shadow mb-[44px]">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Product Breakdown</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div className="dataTables_length" aria-controls="dataTable">
                <label className="form-label">
                  Show&nbsp;
                  <select
                    className="d-inline-block form-select form-select-sm"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      setPage(1);
                      setCurrentStartPage(1);
                      setTotalPages(Math.ceil(count / Number(e.target.value)));
                      setProductForecasts(initialData.slice(0, Number(e.target.value)));
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100" selected>
                      100
                    </option>
                  </select>
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
                <label className="form-label d-flex justify-content-md-end">
                  <DownloadSelector
                    row={row}
                    fileName="Top 100 Products Forecast"
                    downloadColumnHeaders={downloadColumnHeaders}
                    prepareDataFunction={() => {
                      initialData.forEach((item: any) => {
                        row.push([
                          { v: item.description, t: 's' },
                          { v: formatNumOneDecimal(item.year2.totalQuantity), t: 's' },
                          { v: formatNumOneDecimal(item.quantity), t: 's' },
                          {
                            v: `${formatNumOneDecimal(calculateRateChange(Math.floor(item.quantity), item.year2.totalQuantity))}%`,
                            t: 's',
                          },
                          { v: `${formatNumOneDecimal(item.year2.totalRevenue)}`, t: 's' },
                          { v: `$${formatNum(item.revenue)}`, t: 's' },
                          {
                            v: `${formatNumOneDecimal(calculateRateChange(item.revenue, item.year2.totalRevenue))}%`,
                            t: 's',
                          },
                        ]);
                      });
                    }}
                  />
                  &nbsp;
                </label>
                <label className="form-label">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    aria-controls="dataTable"
                    placeholder="Search"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="dont-alternate-row-color">
            <Table striped className="table-sm" bordered {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-gray">
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={index}
                          colSpan={Number(column.width)}
                          className="table-head-font-size"
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                              ) : (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={i}>
                      <tr {...row.getRowProps()} id="1" className="table-body-font-size">
                        {row.cells.map((cell: any, index: number) => {
                          const brandColSpan = index === 1 || index === 6 ? 2 : index === 7 ? 3 : 1;
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              style={{ border: 'none' }}
                              className={'text-dark align-middle'}
                              colSpan={brandColSpan}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {pageSize > count ? count : pageSize} of {count}
              </p>
            </div>
            <div className="col-md-6 mb-2">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => {
                        if (page > 1) {
                          const newPage = page - 1;
                          if (page === currentStartPage) {
                            setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                          }
                          setPage(newPage);
                          const indexOfLastItem = newPage * pageSize;
                          const indexOfFirstItem = indexOfLastItem - pageSize;
                          setProductForecasts(initialData.slice(indexOfFirstItem, indexOfLastItem));
                        }
                      }}
                    >
                      <span aria-hidden="true">«</span>
                    </div>
                  </li>
                  <Pagination
                    currentStartPage={currentStartPage}
                    totalPages={totalPages}
                    startPage={startPage}
                    pageSize={pageSize}
                    count={count}
                    page={page}
                    setPage={setPage}
                    setData={setProductForecasts}
                    initialData={initialData}
                    setCurrentStartPage={setCurrentStartPage}
                  />
                  <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Next"
                      onClick={() => {
                        const newPage = page + 1;
                        setPage(newPage);
                        setCurrentStartPage(currentStartPage + 1);
                        const indexOfLastItem = newPage * pageSize;
                        const indexOfFirstItem = indexOfLastItem - pageSize;
                        setProductForecasts(initialData.slice(indexOfFirstItem, indexOfLastItem));
                      }}
                    >
                      <span aria-hidden="true">»</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h3 className="text-dark mb-4">No Forecasts In the System</h3>
  );
};

export default TopProducts;
