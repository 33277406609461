import React, { type PropsWithChildren, useState } from 'react';

import { createSafeContext } from './createSafeContext';
import ProjectionService from '../services/projection.service';
import ProjectionModel from '../components/projection/ProjectionModel';

interface ProjectionContext {
  isLoading: boolean;
  projectionId: string;
  setProjectionId: (x: string) => void;
  projection: any;
  setProjection: (projection: any) => void;
  getProjection: () => ProjectionModel | null;
  handlers: {
    fetch: (forecastId: string) => Promise<void>;
    fetchByProjectionId: (projectionId: string) => Promise<void>;
    update: (data: any) => Promise<void>;
    discardLine: (data: any) => Promise<void>;
    discard: () => Promise<void>;
    commit: (data: { notes?: string }) => Promise<void>;
    saveNotes: (data: { notes?: string }) => Promise<void>;
  };
}

const [useProjection, Provider] = createSafeContext<ProjectionContext>();

export { useProjection };

export const ProjectionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, $isLoading] = useState<boolean>(false);
  const [projectionId, $projectionId] = useState<string>('');
  const [projection, $projection] = useState<ProjectionModel | null>(null);
  const ref = React.useRef<any>({});
  const setProjection = (projection: any) => {
    ref.current.projection = projection;
    $projection(projection);
  };
  const setProjectionId = (projectionId: string) => {
    ref.current.projectionId = projectionId;
    $projectionId(projectionId);
  };

  const fetch = async (forecastId: string) => {
    if (!forecastId) return;
    try {
      // reset projection
      $isLoading(true);
      if (ref.current.projection) {
        setProjection(null);
        setProjectionId('');
      }
      const res = await ProjectionService.GetProjection({
        forecastId,
      });
      const { projection } = res.data ?? {};
      if (projection) {
        const projectionModel = new ProjectionModel(projection);
        ref.current.projection = projectionModel;
        ref.current.projectionId = projectionModel.projectionId;
        setProjection(projectionModel);
        setProjectionId(projectionModel.projectionId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      $isLoading(false);
    }
  };

  const fetchByProjectionId = async (projectionId: string) => {
    if (!projectionId) return;
    try {
      $isLoading(true);
      if (ref.current.projection) {
        setProjection(null);
        setProjectionId('');
      }
      const res = await ProjectionService.GetProjection({
        projectionId,
      });
      const { projection } = res.data ?? {};
      if (projection) {
        const projectionModel = new ProjectionModel(projection);
        ref.current.projection = projectionModel;
        ref.current.projectionId = projectionModel.projectionId;
        setProjection(projectionModel);
        setProjectionId(projectionModel.projectionId);
      }
    } catch (error) {
      console.log(error);
      setProjection(null);
      setProjectionId('');
    } finally {
      $isLoading(false);
    }
  };

  const discard = async () => {
    const { projectionId } = ref.current;
    if (!projectionId) return;
    return ProjectionService.DiscardProjection({
      projectionId: projectionId,
    })
      .then((_res: any) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commit = async ({ notes }: { notes?: string }) => {
    const { projectionId } = ref.current;
    if (!projectionId) return;
    return ProjectionService.CommitProjection({
      projectionId: projectionId,
      notes,
    })
      .then((_res: any) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveNotes = async ({ notes }: { notes?: string }) => {
    const { projectionId } = ref.current;
    if (!projectionId) return;
    return ProjectionService.SaveNotesProjection({
      projectionId: projectionId,
      notes,
    })
      .then((_res: any) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const update = async (data: any) => {
    const { projectionId } = ref.current;
    const res = await ProjectionService.UpdateForecastBudget({
      ...(projectionId && { projectionId }),
      ...data,
    });
    await fetch(res?.data?.forecastId);
  };

  const discardLine = async (data: any) => {
    const { projectionId } = ref.current;
    const res = await ProjectionService.DiscardForecastBudgetLine({
      ...(projectionId && { projectionId }),
      ...data,
    });
    await fetch(res?.data?.forecastId);
  };

  const value = {
    isLoading,
    projectionId,
    setProjectionId,
    projection,
    setProjection,
    getProjection: () => {
      return ref.current.projection;
    },
    handlers: {
      fetch,
      update,
      discardLine,
      discard,
      commit,
      saveNotes,
      fetchByProjectionId,
    },
  };

  return <Provider value={value}>{children}</Provider>;
};
