import React from 'react';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { validateOnChangeInput } from '../../utils/validation';
import StageButton from '../../components/projection/StageButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export const useBrandFamilyProjectionsTableColumns = (handlers: any) => {
  const { handleChange, handleRemove, handleSaveItem } = handlers;
  return React.useMemo<any>(() => {
    return [
      {
        Header: 'Name',
        accessor: 'brandFamilyName',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          return (
            <div>
              <div>{row.original?.forecastBudget?.brandFamily?.brand?.name}</div>
              <div>{row.original?.forecastBudget?.brandFamily?.name}</div>
            </div>
          );
        },
        Footer: () => {
          return <strong>Grand Total</strong>;
        },
      },
      {
        Header: 'Forecast Cases',
        accessor: 'forecastQuantity',
        Cell: ({ row }: { row: any }) => {
          const value = row.original?.forecastBudget?.quantity || 0;
          return `${formatNumOneDecimal(value)}`;
        },
        Footer: ({ data }: any) => {
          const forecastQuantity = data.reduce((acc: number, row: any) => acc + Number(row.forecastBudget.quantity), 0);
          return <strong>{formatNumOneDecimal(forecastQuantity)}</strong>;
        },
      },
      {
        Header: 'Projected Cases',
        accessor: 'quantity',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const rowData = row.original;
          if (rowData.forecastBudget?.isZeroCase) {
            return (
              <td>
                <div className="d-flex">
                  <input
                    type="text"
                    style={{ width: '45px' }}
                    value={rowData.quantity}
                    onChange={(e) => {
                      validateOnChangeInput(e, rowData.forecastBudgetProjectionId, 'quantity', handleChange);
                    }}
                  />
                </div>
              </td>
            );
          }
          return `${formatNumOneDecimal(rowData.quantity)}`;
        },
        Footer: ({ data }: any) => {
          const quantity = data.reduce((acc: number, row: any) => acc + Number(row.quantity), 0);
          return <strong>{formatNumOneDecimal(quantity)}</strong>;
        },
      },
      {
        Header: 'Net Difference',
        accessor: 'diffQuantity',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const diff = row.original?.quantity - row.original?.forecastBudget?.quantity;
          return `${formatNumOneDecimal(diff)}`;
        },
        Footer: ({ data }: any) => {
          const diffQuantity = data.reduce(
            (acc: number, row: any) => acc + (Number(row.quantity) - Number(row.forecastBudget.quantity)),
            0,
          );
          return <strong>{formatNumOneDecimal(diffQuantity)}</strong>;
        },
      },
      {
        Header: 'Case Growth',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const rowData = row.original;
          return (
            <td>
              <div className="d-flex">
                <input
                  type="text"
                  style={{ width: '45px', paddingRight: 12 }}
                  value={rowData.salesGrowth}
                  onChange={(e) => {
                    validateOnChangeInput(e, rowData.forecastBudgetProjectionId, 'salesGrowth', handleChange);
                  }}
                />
                <span className="" style={{ transform: 'translate(-14px, 3px)' }}>
                  %
                </span>
              </div>
            </td>
          );
        },
        Footer: ({ data }: any) => {
          const salesGrowth =
            (data.reduce((acc: number, row: any) => {
              return acc + formatNegativeNumFromStr(row.salesGrowth);
            }, 0) || 0) / (data.length || 1);
          return <strong>{formatNum(salesGrowth)}</strong>;
        },
      },
      {
        Header: 'Price Growth',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const rowData = row.original;
          return (
            <td>
              <div className="d-flex">
                <input
                  type="text"
                  style={{ width: '45px', paddingRight: 12 }}
                  value={rowData.priceGrowth}
                  onChange={(e) => {
                    validateOnChangeInput(e, rowData.forecastBudgetProjectionId, 'priceGrowth', handleChange);
                  }}
                />
                <span className="" style={{ transform: 'translate(-14px, 3px)' }}>
                  %
                </span>
              </div>
            </td>
          );
        },
        Footer: ({ data }: any) => {
          const priceGrowth =
            (data.reduce((acc: number, row: any) => {
              return acc + formatNegativeNumFromStr(row.priceGrowth);
            }, 0) || 0) / (data.length || 1);
          return <strong>{formatNum(priceGrowth)}</strong>;
        },
      },
      {
        Header: 'Forecasted Revenue',
        accessor: 'forecastRevenue',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const value = row.original?.forecastBudget?.revenue || 0;
          return <td className="cursor-pointer">${formatNum(value)}</td>;
        },
        Footer: ({ data }: any) => {
          const forecastRevenue = data.reduce((acc: number, row: any) => acc + Number(row.forecastBudget.revenue), 0);
          return <strong>${formatNum(forecastRevenue)}</strong>;
        },
      },
      {
        id: 'projectedRevenue',
        Header: 'Projected Revenue',
        canSort: false,
        accessor: 'revenue',
        Cell: ({ value }: { value: any }) => {
          return <td className="cursor-pointer">${formatNum(value)}</td>;
        },
        Footer: ({ data }: any) => {
          const revenue = data.reduce((acc: number, row: any) => acc + Number(row.revenue), 0);
          return <strong>${formatNum(revenue)}</strong>;
        },
      },
      {
        Header: 'Net Difference',
        id: 'diffRevenue',
        accessor: 'diffRevenue',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const diff = row.original?.revenue - row.original?.forecastBudget?.revenue;
          return `$${formatNum(diff)}`;
        },
        Footer: ({ data }: any) => {
          const diffRevenue = data.reduce(
            (acc: number, row: any) => acc + (Number(row.revenue) - Number(row.forecastBudget.revenue)),
            0,
          );
          return <strong>${formatNum(diffRevenue)}</strong>;
        },
      },
      {
        Header: '',
        id: 'actions',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-sm btn-danger float-end"
                type="button"
                onClick={() => {
                  handleRemove(row.original.forecastBudgetProjectionId);
                }}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
              <StageButton
                changedItem={row.original}
                handleSave={() => {
                  handleSaveItem([row.original]);
                }}
                title={<FontAwesomeIcon icon={faInfoCircle} />}
              />
            </div>
          );
        },
      },
    ];
  }, []);
};
