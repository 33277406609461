import React, { type PropsWithChildren, useState } from 'react';

import { createSafeContext } from './createSafeContext';

interface ForecastContext {
  forecastId: string;
  setForecastId: (x: string) => void;
  territoryId: string;
  setTerritoryId: (x: string) => void;
  forecastCalendarId: string;
  setForecastCalendarId: (x: string) => void;
  forecastUpdated: boolean;
  setForecastUpdated: (x: boolean) => void;
  hasForecasts: boolean;
  setHasForecast: (x: boolean) => void;
  showPeriods: boolean;
  setShowPeriods: (x: boolean) => void;
  isForecastFromParams: boolean;
  setIsForecastFromParams: (x: boolean) => void;
  currentForecastYear: number;
  setCurrentForecastYear: (x: number) => void;
}

const [useForecast, Provider] = createSafeContext<ForecastContext>();

export { useForecast };

export const ForecastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const curYear = new Date().getFullYear();
  const [forecastId, setForecastId] = useState<string>('');

  const [territoryId, setTerritoryId] = useState<string>('');
  const [forecastCalendarId, setForecastCalendarId] = useState<string>('');

  const [forecastUpdated, setForecastUpdated] = useState<boolean>(true);
  const [hasForecasts, setHasForecast] = useState<boolean>(true);
  const [showPeriods, setShowPeriods] = useState(false);
  const [currentForecastYear, setCurrentForecastYear] = useState(curYear);

  const [isForecastFromParams, setIsForecastFromParams] = useState(false);

  return (
    <Provider
      value={{
        forecastId,
        setForecastId,
        territoryId,
        setTerritoryId,
        forecastUpdated,
        setForecastUpdated,
        hasForecasts,
        setHasForecast,
        forecastCalendarId,
        setForecastCalendarId,
        showPeriods,
        setShowPeriods,
        currentForecastYear,
        setCurrentForecastYear,
        isForecastFromParams,
        setIsForecastFromParams,
      }}
    >
      {children}
    </Provider>
  );
};
