import Axios from '../lib/axios';

import {
  GetProjectionRequest,
  UpdateForecastBudgetProjectionRequest,
  DiscardForecastBudgetProjectionLineRequest,
  DiscardProjectionRequest,
  CommitProjectionRequest,
  GetProjectionsRequest,
} from '../lib/types';

const GetProjection = (getProjectionRequest: GetProjectionRequest) => {
  return Axios.get(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projection/${getProjectionRequest.projectionId || ''}`,
    {
      params: getProjectionRequest,
    },
  );
};

const GetProjections = (getProjectionRequest: GetProjectionsRequest) => {
  return Axios.get(`${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projections`, {
    params: getProjectionRequest,
  });
};

const UpdateForecastBudget = (updateForecastBudgetProjectionRequest: UpdateForecastBudgetProjectionRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projection/${updateForecastBudgetProjectionRequest.projectionId || ''}`,
    updateForecastBudgetProjectionRequest,
  );
};

const DiscardForecastBudgetLine = (
  discardForecastBudgetProjectionLineRequest: DiscardForecastBudgetProjectionLineRequest,
) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projection/${discardForecastBudgetProjectionLineRequest.projectionId || ''}/discardLine`,
    discardForecastBudgetProjectionLineRequest,
  );
};

const DiscardProjection = (request: DiscardProjectionRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projection/${request.projectionId}/discard`,
    request,
  );
};

const CommitProjection = (request: CommitProjectionRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/admin/forecast/projection/${request.projectionId}/commit`,
    request,
  );
};

const SaveNotesProjection = (request: CommitProjectionRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/projection/${request.projectionId}/saveNotes`,
    request,
  );
};

const ProjectionService = {
  GetProjections,
  GetProjection,
  UpdateForecastBudget,
  DiscardForecastBudgetLine,
  DiscardProjection,
  CommitProjection,
  SaveNotesProjection,
};

export default ProjectionService;
