import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx-js-style';

interface IDownload {
  headers: any;
  data: any;
  fileName: string;
}

export const exportToCSV = ({ headers, data, fileName }: IDownload) => {
  const csvData = XLSX.utils.sheet_to_csv(XLSX.utils.aoa_to_sheet([headers, ...data]));
  const blob = new Blob([csvData], { type: 'text/csv' });
  saveAs(blob, `${fileName}.csv`);
};

// Utility function to convert string to ArrayBuffer
const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const exportToExcel = ({ headers, data, fileName }: IDownload) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  // set first column width and set the rest colums based on the data length
  const wscols = [{ wpx: 300 }];
  for (let i = 0; i < data.length; i++) {
    wscols[i + 1] = { wpx: 150 };
  }
  ws['!cols'] = wscols;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // Convert Excel workbook to a binary string
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  // Create a Blob from the Excel data
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};

export const downloadFile = (data: any, fileName: string) => {
  if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    toast.success('No matching record.');
  }
};

export const parseVerifyXlsData = (file: any, setRows: any) => {
  const reader = new FileReader();
  reader.onload = (e: any) => {
    const data = e.target.result;
    const wb = XLSX.read(data, { type: 'binary' });
    const result = [];
    let rows = [];
    let header = [];
    let title;
    for (let j = 0; j < wb.SheetNames.length; j++) {
      const dataParse = XLSX.utils
        .sheet_to_json(wb.Sheets[wb.SheetNames[j]], { header: 1 })
        .filter((_, i) => i <= 5) as any;
      if (dataParse.length !== 0) {
        title = wb.SheetNames[j];
        header = dataParse[0] as any;
        for (let i = 1; i < dataParse.length; i++) {
          const tmp_data: Record<string, unknown> = header.reduce(
            (obj: { [x: string]: any }, key: string | number, index: string | number) => {
              obj[key] = dataParse[i][index] as any;
              return obj;
            },
            {},
          );
          rows.push({
            ...tmp_data,
            orderDate: tmp_data.orderDate ? new Date(tmp_data.orderDate as any).toLocaleDateString() : '',
          });
        }
        result.push({ title, header, rows });
      } else {
        toast.warn('no data from xlsx file');
        return;
      }
      rows = [];
    }
    setRows(result);

    return result;
  };

  reader.readAsArrayBuffer(new Blob([file]));
};
