import React from 'react';
import { type SelectHTMLAttributes, type ChangeEvent, type ReactNode, useState } from 'react';
import { useField } from 'formik';

export interface Option<TData extends string = string> {
  label: ReactNode;
  value: TData;
  disabled?: boolean;
  icon?: ReactNode;
  [key: string]: unknown;
}

interface SelectProps<TName extends string, TData extends string> extends SelectHTMLAttributes<HTMLSelectElement> {
  name: TName;
  label?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: Option<TData>[];
  placeholder?: ReactNode;
}

export const Select = <TName extends string, TData extends string>({
  name,
  placeholder,
  onChange,
  options,
  label,
  ...rest
}: SelectProps<TName, TData>) => {
  const [field, meta] = useField(name);

  const [showPlaceholder, setShowPlaceholder] = useState(!!placeholder);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.currentTarget.value) {
      return;
    }

    setShowPlaceholder(false);
    onChange?.(event);
    field.onChange(event);
  };

  return (
    <div className="space-y-0.5">
      <label className="flex flex-col">
        <span className="text-xs text-neutral-700">{label}</span>
        <select
          {...field}
          {...rest}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50"
        >
          {showPlaceholder && (
            <option disabled value="">
              {placeholder}
            </option>
          )}
          {options.map(({ label, value, disabled = false }) => (
            <option value={value} disabled={disabled} key={label?.toString() + '_' + value}>
              {label}
            </option>
          ))}
        </select>
      </label>
      {meta.touched && meta.error && <p className="text-sm text-red-500">{meta.error}</p>}
    </div>
  );
};
